import { Component, Inject, OnInit } from '@angular/core';
import { LanguageCode, LanguageDirection, LanguageDto } from '@be-green/dto';
import {
  ErrorService,
  NetworkService,
  SeoService,
  TranslationService,
} from '@be-green/ui-services';

import { environment } from '../environments/environment';
import { LayoutService } from './layout/layout.service';

@Component({
  selector: 'be-green--admin--root',
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit {
  supportedLanguages: LanguageDto[] = [
    {
      code: LanguageCode.French,
      label: 'Français',
      direction: LanguageDirection.LeftToRight,
    },
  ];

  constructor(
    @Inject('API_PAGE_SIZE') readonly apiPageSize: number,
    private readonly errorService: ErrorService,
    private readonly layoutService: LayoutService,
    private readonly networkService: NetworkService,
    private readonly seoService: SeoService,
    private readonly translationService: TranslationService,
  ) {}

  ngOnInit() {
    this.translationService
      .init(this.supportedLanguages)
      .then(() => this.initServices());

    this.seoService.registerAuthor('Tellibus');

    const currentYear = new Date().getFullYear();
    this.layoutService.registerLogoName(
      `© 2023${
        currentYear === 2023 ? '' : ' - ' + currentYear
      } Be Green version ${environment.appVersion}`,
    );
    this.layoutService.registerFooterName('Right Execution Daily');

    this.layoutService.registerMenu([
      {
        label: 'Tableau de bord',
        items: [
          {
            label: 'Accueil',
            icon: 'pi pi-fw pi-home',
            routerLink: ['/dashboard'],
          },

          // {
          //   label: 'Alertes',
          //   icon: 'pi pi-fw pi-exclamation-triangle',
          //   routerLink: ['/alerts'],
          // },

          {
            label: 'Détaillants',
            icon: 'pi pi-fw pi-map-marker',
            routerLink: ['/handlers'],
          },

          {
            label: 'Programmes',
            icon: 'pi pi-fw pi-list',
            routerLink: ['/programs'],
          },

          {
            label: 'Utilisateurs',
            icon: 'pi pi-fw pi-user',
            routerLink: ['/users'],
          },

          {
            label: 'Parrainages',
            icon: 'pi pi-fw pi-users',
            routerLink: ['/referrals'],
          },

          {
            label: 'QR Codes',
            icon: 'pi pi-fw pi-qrcode',
            routerLink: ['/baskets'],
            queryParams: {
              first: 0,
              rows: this.apiPageSize,
              sortField: '',
              sortOrder: 1,
            },
          },

          {
            label: 'Échanges',
            icon: 'pi pi-fw pi-gift',
            routerLink: ['/redemptions'],
          },
        ],
      },

      {
        label: 'Configuration',
        items: [
          {
            label: 'Actualités',
            icon: 'pi pi-fw pi-megaphone',
            routerLink: ['/articles'],
          },

          {
            label: 'Astuces écolos',
            icon: 'pi pi-fw pi-bolt',
            routerLink: ['/tips'],
          },

          {
            label: 'Matériaux',
            icon: 'pi pi-fw pi-box',
            routerLink: ['/materials'],
          },

          {
            label: 'Types d’organisme',
            icon: 'pi pi-fw pi-building',
            routerLink: ['/organization-types'],
          },

          {
            label: 'Qualité de collecte',
            icon: 'pi pi-fw pi-thumbs-up',
            routerLink: ['/qualities'],
          },

          {
            label: 'Vidéos de démo',
            icon: 'pi pi-fw pi-youtube',
            routerLink: ['/demo-videos'],
          },

          {
            label: 'C.G.U.',
            icon: 'pi pi-fw pi-info-circle',
            routerLink: ['/terms'],
          },

          {
            label: 'F.A.Q.',
            icon: 'pi pi-fw pi-question-circle',
            routerLink: ['/faq'],
          },
        ],
      },

      {
        label: 'Administration',
        items: [
          {
            label: 'Comptes',
            icon: 'pi pi-fw pi-key',
            routerLink: ['/accounts'],
          },

          // {
          //   label: 'Compte e-mail',
          //   icon: 'pi pi-fw pi-envelope',
          //   routerLink: ['/email-account'],
          // },

          // {
          //   label: 'Etat du système',
          //   icon: 'pi pi-fw pi-server',
          //   routerLink: ['/system-status'],
          // },
        ],
      },
    ]);
  }

  private initServices() {
    this.errorService.init();
    this.networkService.init();
  }
}
