<div class="card">
  <p-table
    #organizationTypeIndexDataTable
    styleClass="p-datatable-gridlines"
    [currentPageReportTemplate]="
      'Enregistrements {first} à {last} sur un total de {totalRecords}'
    "
    [dataKey]="'code'"
    [globalFilterFields]="['nameAr', 'nameFr']"
    [loading]="isLoading"
    [paginator]="true"
    [responsiveLayout]="'scroll'"
    [rowHover]="true"
    [rows]="10"
    [rowsPerPageOptions]="[10, 25, 50]"
    [scrollable]="true"
    [showCurrentPageReport]="true"
    [value]="organizationTypes"
    (onRowReorder)="onRowReorder($event)"
  >
    <ng-template pTemplate="caption">
      <div
        class="flex justify-content-between align-items-start flex-column sm:flex-row"
      >
        <div class="flex-column sm:flex-row mb-2">
          <button
            class="p-button-outlined sm:mb-2 mr-2"
            (click)="clearOrganizationTypesTable()"
            pButton
            label="Effacer tous les filtres"
            icon="pi pi-filter-slash"
          ></button>

          <button
            class="mb-2 p-button-success"
            routerLink="/organization-types/create"
            pButton
            label="Créer un type d’organisme"
            icon="pi pi-plus"
          ></button>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th pFrozenColumn></th>

        <th style="min-width: 20rem" pSortableColumn="nameFr" pFrozenColumn>
          <div class="flex justify-content-between align-items-center">
            <div class="flex align-items-center gap-2">
              <img src="assets/flags/fr.svg" height="20" />
              Nom
            </div>

            <p-sortIcon field="nameFr"></p-sortIcon>
          </div>
        </th>

        <th style="min-width: 20rem" pSortableColumn="nameAr">
          <div class="flex justify-content-between align-items-center">
            <div class="flex align-items-center gap-2">
              <img src="assets/flags/ma.svg" height="20" />
              Nom
            </div>

            <p-sortIcon field="nameAr"></p-sortIcon>
          </div>
        </th>

        <th style="min-width: 20rem">Programmes rattachés</th>

        <th alignFrozen="right" pFrozenColumn></th>
      </tr>

      <tr>
        <th pFrozenColumn></th>

        <th pFrozenColumn>
          <p-columnFilter type="text" field="nameFr"></p-columnFilter>
        </th>

        <th>
          <p-columnFilter type="text" field="nameAr"></p-columnFilter>
        </th>

        <th></th>

        <th alignFrozen="right" pFrozenColumn></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-organizationType let-index="rowIndex">
      <ng-container
        *ngLet="asOrganizationTypeDto(organizationType) as organizationType"
      >
        <tr [pReorderableRow]="index" *ngIf="organizationType">
          <td pFrozenColumn>
            <span class="pi pi-bars" pReorderableRowHandle></span>
          </td>

          <td pFrozenColumn>
            {{ organizationType.nameFr }}
          </td>

          <td class="text-right" dir="rtl">
            {{ organizationType.nameAr }}
          </td>

          <td class="text-right">
            {{ organizationType.programs?.length }}
          </td>

          <td alignFrozen="right" pFrozenColumn>
            <button
              class="p-button-rounded"
              type="button"
              pButton
              icon="pi pi-search"
              [routerLink]="'/organization-types/' + organizationType.code"
            ></button>
          </td>
        </tr>
      </ng-container>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="4">Aucun type d’organisme trouvée.</td>
      </tr>
    </ng-template>

    <ng-template pTemplate="loadingbody">
      <tr>
        <td colspan="4">Chargement en cours. Veuillez patienter...</td>
      </tr>
    </ng-template>
  </p-table>
</div>

<ng-template #notSet>
  <span class="text-pink-600 font-italic">Non défini</span>
</ng-template>
