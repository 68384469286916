import { Environment } from '../../../environment.interface';

export const environment: Environment = {
  production: true,
  scope: 'admin',
  platform: 'web',

  appVersion: '1.4.3-0',

  apiBaseUrl: 'https://api.bg.tellibus.com/v1',
  deepLinkBaseUrl: 'https://bguat.tellibus.com/referraluser/',
  imageBaseUrl: 'https://shared.bg.tellibus.com',
  socketIoUrl: 'https://api.bg.tellibus.com',

  apiPageSize: 10,

  google: { maps: { key: 'AIzaSyDwgDTuEoUdQ6PovlP9chEZDxDji2KTIGU' } },

  jwt: { refreshTokenNonce: 'TzNYYsG1eo' },

  publicUrl: 'bo.bg.tellibus.com',
};
