import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { OrganizationTypeApi } from '@be-green/api-services';
import { OrganizationTypeDto } from '@be-green/dto';
import { ErrorService, SeoService } from '@be-green/ui-services';
import { Table } from 'primeng/table';
import { LayoutService } from '../../layout/layout.service';

@Component({
  selector: 'be-green--admin--organization-types-index',
  templateUrl: './organization-types-index.component.html',
  styleUrls: ['./organization-types-index.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrganizationTypesIndexComponent implements OnInit {
  @ViewChild('filterOrganizationTypeIndexDataTable')
  filterOrganizationTypeIndexDataTable!: ElementRef;
  @ViewChild('organizationTypeIndexDataTable')
  organizationTypeIndexDataTable!: Table;

  isLoading = false;
  organizationTypes!: OrganizationTypeDto[];

  constructor(
    private readonly organizationTypeApi: OrganizationTypeApi,
    private readonly errorService: ErrorService,
    private readonly layoutService: LayoutService,
    private readonly seoService: SeoService,
  ) {
    this.layoutService.registerBreadcrumbs([{ label: 'Types d’organisme' }]);
    this.seoService.setTitle('Types d’organisme - Admin - Be Green');
  }

  ngOnInit(): void {
    this.isLoading = true;

    this.getAllorganizationTypes();
  }

  private getAllorganizationTypes() {
    this.organizationTypeApi.getAll().subscribe({
      next: (organizationTypes) => {
        this.organizationTypes = organizationTypes;

        this.isLoading = false;
      },

      error: (error: HttpErrorResponse) => {
        this.errorService.handleError(error);

        this.isLoading = false;
      },
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  asOrganizationTypeDto(organizationType: any): OrganizationTypeDto {
    return organizationType as OrganizationTypeDto;
  }

  clearOrganizationTypesTable() {
    this.organizationTypeIndexDataTable.clear();
    this.filterOrganizationTypeIndexDataTable.nativeElement.value = '';
  }

  eventTargetValue(event: Event) {
    return (event.target as HTMLInputElement).value;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onRowReorder(event: { dragIndex: number; dropIndex: number }): void {
    this.isLoading = true;

    this.organizationTypeApi
      .reorder(
        this.organizationTypes.map((organizationType) => organizationType.code),
      )
      .subscribe({
        next: () => (this.isLoading = false),

        error: async (error: HttpErrorResponse) => {
          this.isLoading = false;

          this.errorService.handleError(error);
        },
      });
  }
}
