import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { RedemptionApi } from '@be-green/api-services';
import {
  RedemptionDto,
  RedemptionStatusCode,
  ValueLabelDto,
} from '@be-green/dto';
import { ErrorService, SeoService, UtilsService } from '@be-green/ui-services';
import { format } from 'date-fns';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { LayoutService } from '../../layout/layout.service';

@Component({
  selector: 'be-green--admin--redemptions-index',
  templateUrl: './redemptions-index.component.html',
  styleUrls: ['./redemptions-index.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RedemptionsIndexComponent implements OnInit {
  @ViewChild('redemptionIndexDataTable') redemptionIndexDataTable!: Table;

  redemptions!: RedemptionDto[];
  isLoading = false;
  isUploadResultsDialogVisible = false;
  recordCount = 0;
  statuses: ValueLabelDto[] = [
    { value: RedemptionStatusCode.Requested, label: 'Demandé' },
    { value: RedemptionStatusCode.Scanned, label: 'Scanné' },
    { value: RedemptionStatusCode.Approved, label: 'Approuvé' },
    { value: RedemptionStatusCode.Rejected, label: 'Rejeté' },
  ];
  uploadResultsFileUploadUrl = `/redemptions/upload/results`;

  constructor(
    @Inject('API_PAGE_SIZE') readonly apiPageSize: number,
    private readonly errorService: ErrorService,
    private readonly redemptionApi: RedemptionApi,
    private readonly layoutService: LayoutService,
    private readonly messageService: MessageService,
    private readonly seoService: SeoService,
  ) {
    this.layoutService.registerBreadcrumbs([{ label: 'Échanges' }]);
    this.seoService.setTitle('Échanges - Admin - Be Green');
  }

  ngOnInit(): void {
    this.isLoading = true;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  asRedemptionDto(redemption: any): RedemptionDto {
    return redemption as RedemptionDto;
  }

  eventTargetValue(event: Event) {
    return (event.target as HTMLInputElement).value;
  }

  export() {
    this.isLoading = true;

    this.redemptionApi.export().subscribe({
      next: async (blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        document.body.appendChild(link);
        link.setAttribute('style', 'display: none');
        link.href = url;

        const filename = UtilsService.getFilenameFromResponseHeaders(blob);
        link.download =
          filename ??
          `redemptions--${format(new Date(), 'yyyy-MM-dd_HH-mm-ss')}.xlsx`;

        link.click();
        window.URL.revokeObjectURL(url);
        link.remove();

        this.isLoading = false;
      },

      error: async (error: HttpErrorResponse) => {
        const { title, message } = await this.errorService.handleError(error);

        this.isLoading = false;

        this.messageService.add({
          detail: message,
          key: 'redemptions',
          severity: 'error',
          summary: title,
        });
      },
    });
  }

  fetchNextPage(lazyLoadEvent: LazyLoadEvent) {
    this.isLoading = true;

    this.redemptionApi
      .getAll(
        UtilsService.fromLazyLoadEventToQueryUiDto(
          lazyLoadEvent,
          this.apiPageSize,
        ),
      )
      .subscribe({
        next: (data) => {
          this.redemptions = data.rows;
          this.recordCount = data.count;

          this.isLoading = false;
        },

        error: (error: HttpErrorResponse) => {
          this.errorService.handleError(error);

          this.isLoading = false;
        },
      });
  }

  getStatusTagSeverity(
    statusCode: string,
  ): 'primary' | 'danger' | 'success' | 'warning' | '' {
    return UtilsService.getRedemptionStatusTagSeverity(statusCode);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUploadError(event: { files: File[]; error: any }) {
    this.messageService.add({
      severity: 'error',
      summary: event.error.error?.reason,
      detail: event.error.error?.message,
    });

    this.isLoading = false;
    this.isUploadResultsDialogVisible = false;
  }

  onUploadResultsSuccess(event: {
    files: File[];
    originalEvent: HttpResponse<{
      url: string;
      warnings?: {
        ignoredSheets: string[];
        ignoredStatuses: {
          line: number;
          currentStatus: string;
          newStatus: string;
        }[];
        invalidCodes: { line: number; code: string }[];
        invalidStatuses: { line: number; status: string }[];
      };
    }>;
  }) {
    if (
      event.originalEvent.body?.warnings &&
      (event.originalEvent.body.warnings.ignoredSheets.length ||
        event.originalEvent.body.warnings.ignoredStatuses.length ||
        event.originalEvent.body.warnings.invalidCodes.length ||
        event.originalEvent.body.warnings.invalidStatuses.length)
    ) {
      if (event.originalEvent.body.warnings.ignoredSheets.length) {
        this.messageService.add({
          detail: `L’importation du fichier « ${
            event.files[0].name
          } » est terminée. Toutefois, une seule feuille peut être importée et les feuilles suivantes ont été ignorées : ** ${event.originalEvent.body.warnings.ignoredSheets.join(
            ' * , * ',
          )} **`,
          severity: 'warn',
          summary: 'Importation complétée',
        });
      }

      if (event.originalEvent.body.warnings.ignoredStatuses.length) {
        this.messageService.add({
          detail: `L’importation du fichier « ${
            event.files[0].name
          } » est terminée. Toutefois, ces lignes ont été ignorées et les récompenses correspondantes n’ont pas été mises à jour dans la base car leurs états sont incompatibles ou redondants : ** ${event.originalEvent.body.warnings.ignoredStatuses
            .map(
              (ignoredStatus) =>
                `Ligne ${ignoredStatus.line} : statut dans le fichier téléversé « ${ignoredStatus.newStatus} », statut dans la base « ${ignoredStatus.currentStatus} » ` +
                (ignoredStatus.newStatus === ignoredStatus.currentStatus
                  ? '(inchangé)'
                  : '(changement interdit)'),
            )
            .join(' * , * ')} **`,
          severity: 'warn',
          summary: 'Importation complétée',
        });
      }

      if (event.originalEvent.body.warnings.invalidCodes.length) {
        this.messageService.add({
          detail: `L’importation du fichier « ${
            event.files[0].name
          } » est terminée. Toutefois, ces récompenses n’ont pas été mises à jour dans la base car leurs codes ne sont pas valides : ** ${event.originalEvent.body.warnings.invalidCodes
            .map(
              (invalidCode) =>
                `"${invalidCode.code}" à la ligne ${invalidCode.line}`,
            )
            .join(' * , * ')} **`,
          severity: 'warn',
          summary: 'Importation complétée',
        });
      }

      if (event.originalEvent.body.warnings.invalidStatuses.length) {
        this.messageService.add({
          detail: `L’importation du fichier « ${
            event.files[0].name
          } » est terminée. Toutefois, ces récompenses n’ont pas été mises à jour dans la base car leurs états ne sont pas valides : ** ${event.originalEvent.body.warnings.invalidStatuses
            .map(
              (invalidStatus) =>
                `"${invalidStatus.status}" à la ligne ${invalidStatus.line}`,
            )
            .join(' * , * ')} **`,
          severity: 'warn',
          summary: 'Importation complétée',
        });
      }
    } else {
      this.messageService.add({
        severity: 'success',
        summary: 'Importation réussie',
        detail: `L’importation et le traitement du fichier « ${event.files[0].name} » sont terminés.`,
      });
    }

    this.redemptionIndexDataTable.reset();

    this.isLoading = false;
    this.isUploadResultsDialogVisible = false;
  }

  uploadResults() {
    this.isUploadResultsDialogVisible = true;
  }
}
