import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { ParticipationApi } from '@be-green/api-services';
import { ParticipationDto } from '@be-green/dto';
import { ErrorService, SeoService, UtilsService } from '@be-green/ui-services';
import { format } from 'date-fns';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { LayoutService } from '../../layout/layout.service';

@Component({
  selector: 'be-green--admin--referrals-index',
  templateUrl: './referrals-index.component.html',
  styleUrls: ['./referrals-index.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReferralsIndexComponent implements OnInit {
  isLoading = false;
  recordCount = 0;
  referrals!: ParticipationDto[];

  constructor(
    @Inject('API_PAGE_SIZE') readonly apiPageSize: number,
    private readonly errorService: ErrorService,
    private readonly participationApi: ParticipationApi,
    private readonly layoutService: LayoutService,
    private readonly messageService: MessageService,
    private readonly seoService: SeoService,
  ) {
    this.layoutService.registerBreadcrumbs([{ label: 'Parrainages' }]);
    this.seoService.setTitle('Parrainages - Admin - Be Green');
  }

  ngOnInit(): void {
    this.isLoading = true;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  asParticipationDto(participation: any): ParticipationDto {
    return participation as ParticipationDto;
  }

  eventTargetValue(event: Event) {
    return (event.target as HTMLInputElement).value;
  }

  export() {
    this.isLoading = true;

    this.participationApi.exportReferrals().subscribe({
      next: async (blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        document.body.appendChild(link);
        link.setAttribute('style', 'display: none');
        link.href = url;

        const filename = UtilsService.getFilenameFromResponseHeaders(blob);
        link.download =
          filename ??
          `referrals--${format(new Date(), 'yyyy-MM-dd_HH-mm-ss')}.xlsx`;

        link.click();
        window.URL.revokeObjectURL(url);
        link.remove();

        this.isLoading = false;
      },

      error: async (error: HttpErrorResponse) => {
        const { title, message } = await this.errorService.handleError(error);

        this.isLoading = false;

        this.messageService.add({
          detail: message,
          key: 'referrals',
          severity: 'error',
          summary: title,
        });
      },
    });
  }

  fetchNextPage(lazyLoadEvent: LazyLoadEvent) {
    this.isLoading = true;

    this.participationApi
      .getAllReferrals(
        UtilsService.fromLazyLoadEventToQueryUiDto(
          lazyLoadEvent,
          this.apiPageSize,
        ),
      )
      .subscribe({
        next: (data) => {
          this.referrals = data.rows;
          this.recordCount = data.count;

          this.isLoading = false;
        },

        error: (error: HttpErrorResponse) => {
          this.errorService.handleError(error);

          this.isLoading = false;
        },
      });
  }
}
