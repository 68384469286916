import {
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  Params,
  RouteReuseStrategy,
} from '@angular/router';

export class ReloadRouteOnParamChangeStrategy implements RouteReuseStrategy {
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    // Not storing routes
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return null;
  }

  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot,
  ): boolean {
    // Reuse the route if the configurations are the same and parameters haven't changed
    if (future.routeConfig !== curr.routeConfig) {
      return false;
    }

    return (
      this.areParamsEqual(future.params, curr.params) &&
      this.areParamsEqual(future.queryParams, curr.queryParams)
    );
  }

  private areParamsEqual(futureParams: Params, currParams: Params): boolean {
    const futureKeys = Object.keys(futureParams);
    const currKeys = Object.keys(currParams);

    if (futureKeys.length !== currKeys.length) {
      return false;
    }

    return futureKeys.every((key) => futureParams[key] === currParams[key]);
  }
}

export const reloadRouteOnParamChangeStrategyProvider = {
  provide: RouteReuseStrategy,
  useClass: ReloadRouteOnParamChangeStrategy,
};
