<ng-template #loadingSkeleton>
  <div class="field col-12 md:col-6 md:pr-6 pr-0">
    <h5>Loading...</h5>

    <div class="custom-skeleton p-4">
      <div class="flex mb-3">
        <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>

        <div>
          <p-skeleton width="10rem" styleClass="mb-2"></p-skeleton>
          <p-skeleton width="5rem" styleClass="mb-2"></p-skeleton>
          <p-skeleton height=".5rem"></p-skeleton>
        </div>
      </div>

      <p-skeleton width="100%" height="150px"></p-skeleton>

      <div class="flex justify-content-between mt-6">
        <p-skeleton width="4rem" height="2rem"></p-skeleton>
        <p-skeleton width="4rem" height="2rem"></p-skeleton>
      </div>
    </div>
  </div>
</ng-template>

<div class="grid flex frex-wrap">
  <div class="col-12 xl:col-7">
    <div class="card">
      <div
        class="flex align-items-start flex-column lg:justify-content-between lg:flex-row"
        *ngIf="redemption; else loadingSkeleton"
      >
        <div class="flex flex-column">
          <div
            class="flex flex-wrap align-items-center"
            *ngIf="redemption.participation"
          >
            <div class="flex mr-4" style="line-height: 0">
              <p-image
                height="49.5"
                [preview]="true"
                [src]="imageBaseUrl + redemption.participation.program.imageUrl"
                *ngIf="
                  redemption.participation.program.imageUrl;
                  else noPicture
                "
              ></p-image>

              <ng-template #noPicture>
                <p-avatar
                  [label]="redemption.participation.program.nameFr.charAt(0)"
                  size="xlarge"
                  styleClass="mr-2"
                ></p-avatar>
              </ng-template>
            </div>

            <div class="font-bold text-3xl text-900">
              <a
                [routerLink]="
                  '/programs/' + redemption.participation.program.code
                "
              >
                {{ redemption.participation.program.nameFr }}
                <i class="pi pi-external-link"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div
        class="flex align-items-center justify-content-between mb-3"
        *ngIf="redemption; else loadingSkeleton"
      >
        <h3 class="text-2xl font-bold mb-0">Récompense</h3>
      </div>

      <p-table
        styleClass="p-datatable-gridlines"
        [currentPageReportTemplate]="
          'Enregistrements {first} à {last} sur un total de {totalRecords}'
        "
        [dataKey]="'code'"
        [loading]="isLoading"
        [paginator]="true"
        [responsiveLayout]="'scroll'"
        [rowHover]="true"
        [rows]="10"
        [rowsPerPageOptions]="[10, 25, 50]"
        [showCurrentPageReport]="true"
        [value]="[redemption.gift]"
        *ngIf="redemption; else loadingSkeleton"
      >
        <ng-template pTemplate="header">
          <tr>
            <th></th>
            <th>Nom</th>
            <th>Solde avant</th>
            <th>Solde utilisé</th>
            <th>Solde après</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-gift>
          <ng-container *ngLet="asGiftDto(gift) as gift">
            <tr *ngIf="gift">
              <td style="text-align: center">
                <div class="flex justify-content-center" style="line-height: 0">
                  <p-image
                    class="shadow-4"
                    [preview]="true"
                    [src]="
                      imageBaseUrl +
                      gift.imageUrl.replace(
                        '__PROGRAM_CODE__',
                        redemption.participation.program.code
                      )
                    "
                    width="100"
                    *ngIf="redemption.participation"
                  ></p-image>
                </div>
              </td>

              <td>
                {{ gift.nameFr }}
              </td>

              <td class="text-right">
                {{ redemption.previousPoints | number : '1.0-0' : 'fr' }}
              </td>

              <td class="text-right">
                {{ redemption.points | number : '1.0-0' : 'fr' }}
              </td>

              <td class="text-right">
                {{
                  redemption.previousPoints
                    ? (redemption.previousPoints - redemption.points
                      | number : '1.0-0' : 'fr')
                    : ''
                }}
              </td>
            </tr>
          </ng-container>
        </ng-template>
      </p-table>
    </div>
  </div>

  <div class="col-12 xl:col-5" *ngIf="redemption; else loadingSkeleton">
    <div class="card">
      <div
        class="flex justify-content-between align-items-center text-700 flex-wrap text-xl"
      >
        <div
          class="mr-5 flex align-items-center my-3"
          *ngIf="redemption.participation"
        >
          <i class="pi pi-user mr-2"></i>

          <a
            [routerLink]="
              '/handlers/' + redemption.participation.person.handler.code
            "
            *ngIf="redemption.participation.person.handler; else userLink"
          >
            {{ redemption.participation.person.firstName }}
            {{ redemption.participation.person.lastName }}
            <i class="pi pi-external-link"></i>
          </a>

          <ng-template #userLink>
            <a
              [routerLink]="'/users/' + redemption.createdBy.code"
              *ngIf="redemption.createdBy"
            >
              {{ redemption.participation.person.firstName }}
              {{ redemption.participation.person.lastName }}
              <i class="pi pi-external-link"></i>
            </a>
          </ng-template>
        </div>

        <div
          class="mr-5 flex align-items-center my-3"
          *ngIf="!redemption.formValues"
        >
          <i class="pi pi-map-marker mr-2"></i>

          <a
            [routerLink]="'/handlers/' + redemption.programHandler.handler.code"
            *ngIf="redemption.programHandler; else notSet"
          >
            {{ redemption.programHandler.handler.nameFr }}
            <i class="pi pi-external-link"></i>
          </a>
        </div>
      </div>
    </div>

    <div class="card" *ngIf="redemption?.formValues; else qrCode">
      <div class="flex align-items-center justify-content-between mb-3">
        <h3 class="text-2xl font-bold mb-0">Formulaire</h3>
      </div>

      <div>
        <ul class="list-none p-0 m-0 flex-grow-1">
          <li
            class="flex align-items-center py-3 px-2 border-bottom-1 surface-border flex-wrap"
            *ngFor="let formValue of redemption?.formValues"
          >
            <div
              class="text-500 w-full xl:w-8 font-bold overflow-hidden text-overflow-ellipsis"
            >
              {{ 'GENERIC.REDEMPTION.' + formValue.field | translate }}
            </div>

            <div class="text-900 w-full xl:w-4">
              {{ formValue.value }}
            </div>
          </li>
        </ul>
      </div>

      <ng-content *ngTemplateOutlet="status"></ng-content>
    </div>

    <ng-template #qrCode>
      <div class="card">
        <qrcode
          [cssClass]="'text-center tlb--qr-code'"
          [errorCorrectionLevel]="'M'"
          [imageSrc]="'./assets/images/ui/gift-positive-213x213.png'"
          [imageHeight]="75"
          [imageWidth]="75"
          [qrdata]="redemption.code"
          [width]="352"
        ></qrcode>

        <ng-content
          *ngTemplateOutlet="status; context: { css: 'lg:mt-0' }"
        ></ng-content>
      </div>
    </ng-template>

    <ng-template #status let-css="css">
      <div
        class="my-4 flex justify-content-center align-items-center gap-2"
        [ngClass]="css"
      >
        <p-tag
          [value]="redemption.status.name"
          [severity]="getStatusTagSeverity(redemption.status.code)"
          *ngIf="redemption.status"
        ></p-tag>

        {{ redemption.updatedAt | date : 'long' : '' : 'fr' }}
      </div>
    </ng-template>
  </div>
</div>

<ng-template #notSet>
  <span class="text-pink-600 font-italic">Non défini</span>
</ng-template>
