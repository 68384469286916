<ng-template #loadingSkeleton>
  <div class="field col-12 md:col-6 md:pr-6 pr-0">
    <h5>Loading...</h5>

    <div class="custom-skeleton p-4">
      <div class="flex mb-3">
        <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>

        <div>
          <p-skeleton width="10rem" styleClass="mb-2"></p-skeleton>
          <p-skeleton width="5rem" styleClass="mb-2"></p-skeleton>
          <p-skeleton height=".5rem"></p-skeleton>
        </div>
      </div>

      <p-skeleton width="100%" height="150px"></p-skeleton>

      <div class="flex justify-content-between mt-6">
        <p-skeleton width="4rem" height="2rem"></p-skeleton>
        <p-skeleton width="4rem" height="2rem"></p-skeleton>
      </div>
    </div>
  </div>
</ng-template>

<p-toolbar styleClass="mb-4 bg-white">
  <div class="p-toolbar-group-start">
    <p-selectButton
      [options]="segments"
      [(ngModel)]="segment"
      optionLabel="label"
      optionValue="value"
    ></p-selectButton>
  </div>

  <div class="p-toolbar-group-end">
    <button
      class="p-button p-button-info mr-2"
      pButton
      pRipple
      label="Lien pour users"
      icon="pi pi-link"
      (click)="showProAffiliationLink()"
      *ngIf="program?.type === 'pro'"
    ></button>

    <p-splitButton
      [disabled]="isLoading"
      [icon]="actionMenuItems[0].icon!!"
      [label]="actionMenuItems[0].label!!"
      [model]="actionMenuItems"
      [styleClass]="actionDefaultStyleClass"
      (onClick)="actionMenuItems[0].command!!()"
    ></p-splitButton>

    <p-splitButton
      [disabled]="isLoading"
      [icon]="exportMenuItems[0].icon!!"
      [label]="exportMenuItems[0].label!!"
      [model]="exportMenuItems"
      [styleClass]="exportDefaultStyleClass"
      (onClick)="exportMenuItems[0].command!!()"
    ></p-splitButton>
  </div>
</p-toolbar>

<p-messages></p-messages>

<div class="grid flex frex-wrap" *ngIf="segment === 'details'">
  <div class="col-12">
    <div class="card mb-0">
      <div
        class="flex align-items-start flex-column lg:justify-content-between lg:flex-row"
        *ngIf="program; else loadingSkeleton"
      >
        <div class="flex flex-column">
          <div class="mb-2">
            <p-tag
              [value]="
                program.type === 'goodDeal'
                  ? 'Bon plan'
                  : program.type === 'pro'
                  ? 'Pro'
                  : 'Recyclage'
              "
              [severity]="getTypeTagSeverity(program.type)"
            ></p-tag>

            <p-tag
              class="ml-2"
              [value]="program.organizationType.nameFr"
              severity="info"
              *ngIf="program.type === 'pro' && program.organizationType"
            ></p-tag>

            <p-tag
              class="ml-2"
              [value]="
                'Collecte ' +
                (program.collectionType === 'realTime'
                  ? 'temps réel'
                  : 'différée')
              "
              [severity]="getCollectionTypeTagSeverity(program.collectionType)"
              *ngIf="program.type === 'recycling'"
            ></p-tag>
          </div>

          <div class="flex flex-wrap align-items-center">
            <div class="flex mr-4" style="line-height: 0">
              <p-image
                width="100"
                [preview]="true"
                [src]="imageBaseUrl + program.imageUrl"
                *ngIf="program.imageUrl; else noPicture"
              ></p-image>

              <ng-template #noPicture>
                <p-avatar
                  [label]="program.nameFr.charAt(0)"
                  size="xlarge"
                  styleClass="mr-2"
                ></p-avatar>
              </ng-template>
            </div>

            <div class="font-bold text-3xl text-900">
              {{ program.nameFr }}
            </div>
          </div>

          <div class="flex align-items-center text-700 flex-wrap">
            <div class="mr-5 flex align-items-center mt-3">
              <i class="pi pi-clock mr-2"></i>
              <span
                >Créé :
                {{ program.createdAt | date : 'long' : '' : 'fr' }}</span
              >
            </div>

            <div class="mr-5 flex align-items-center mt-3">
              <i class="pi pi-history mr-2"></i>
              <span
                >Mis à jour :
                <ng-container *ngIf="program.updatedAt; else notSet">
                  {{ program.updatedAt | date : 'long' : '' : 'fr' }}
                </ng-container>
              </span>
            </div>
          </div>
        </div>

        <div class="mt-3 lg:mt-0 flex align-items-center gap-4">
          <div
            class="flex flex-column align-items-center"
            *ngIf="program.type === 'pro'"
          >
            <div
              class="flex align-items-center justify-content-center"
              style="width: 2.5rem; height: 2.5rem"
            >
              <i class="pi pi-hashtag text-xl"></i>
            </div>

            <ng-container *ngIf="program.currentRank; else notSet">
              {{ program.currentRank }}
            </ng-container>
          </div>

          <div
            class="flex flex-column align-items-center"
            *ngIf="program.type === 'pro'"
          >
            <div
              class="flex align-items-center justify-content-center"
              style="width: 2.5rem; height: 2.5rem"
            >
              <i class="pi pi-qrcode text-xl"></i>
            </div>

            <ng-container
              *ngIf="program.currentPeriodApprovedBasketCount; else notSet"
            >
              {{ program.currentPeriodApprovedBasketCount }}
            </ng-container>
          </div>

          <div class="flex flex-column align-items-center">
            <div
              class="flex align-items-center justify-content-center bg-purple-100 border-round"
              style="width: 2.5rem; height: 2.5rem"
            >
              <i class="pi pi-user text-purple-500 text-xl"></i>
            </div>

            {{ program.userCount | number : '1.0-0' : 'fr' }}
          </div>

          <div class="flex flex-column align-items-center">
            <div
              class="flex align-items-center justify-content-center bg-green-100 border-round"
              style="width: 2.5rem; height: 2.5rem"
            >
              <i class="pi pi-inbox text-green-500 text-xl"></i>
            </div>

            {{ program.collectedQuantity | number : '1.0-0' : 'fr' }}
          </div>

          <div class="flex flex-column align-items-center">
            <div
              class="flex align-items-center justify-content-center bg-orange-100 border-round"
              style="width: 2.5rem; height: 2.5rem"
            >
              <i class="pi pi-map-marker text-orange-500 text-xl"></i>
            </div>

            {{ program.collectedWeight | number : '1.0-0' : 'fr' }} g
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 md:col-5">
    <div class="card">
      <div
        class="flex align-items-center justify-content-between"
        [ngClass]="{ 'mb-3': isGeneralCardOpen }"
      >
        <h3 class="text-2xl font-bold mb-0">Général</h3>

        <div>
          <button
            class="p-button-rounded p-button-text"
            type="button"
            pButton
            tabindex="-1"
            [icon]="'pi ' + (isGeneralCardOpen ? 'pi-minus' : 'pi-plus')"
            (click)="isGeneralCardOpen = !isGeneralCardOpen"
          ></button>
        </div>
      </div>

      <div [@toggleCardContents]="isGeneralCardOpen">
        <ul
          class="list-none p-0 m-0 flex-grow-1"
          *ngIf="program; else loadingSkeleton"
        >
          <li
            class="flex align-items-center py-3 px-2 border-bottom-1 surface-border flex-wrap"
          >
            <div
              class="text-500 w-full xl:w-4 font-bold overflow-hidden text-overflow-ellipsis"
            >
              Nom
            </div>

            <div class="text-900 w-full xl:w-8">
              <div class="flex align-items-center mb-4">
                <img class="mr-2" src="/assets/flags/fr.svg" height="20" />

                <ng-container *ngIf="program.nameFr; else notSet">{{
                  program.nameFr
                }}</ng-container>
              </div>

              <div class="flex align-items-center">
                <img class="mr-2" src="/assets/flags/ma.svg" height="20" />

                <ng-container *ngIf="program.nameAr; else notSet">{{
                  program.nameAr
                }}</ng-container>
              </div>
            </div>
          </li>

          <li
            class="flex align-items-center py-3 px-2 border-bottom-1 surface-border flex-wrap"
          >
            <div
              class="text-500 w-full xl:w-5 font-bold overflow-hidden text-overflow-ellipsis"
            >
              Dates
            </div>

            <div class="text-900 w-full xl:w-7">
              <p>
                Du {{ program.startDate | date : 'mediumDate' : '' : 'fr' }}
              </p>
              <p>Au {{ program.endDate | date : 'mediumDate' : '' : 'fr' }}</p>
            </div>
          </li>

          <li
            class="flex align-items-center py-3 px-2 border-bottom-1 surface-border flex-wrap"
            *ngIf="program.type !== 'goodDeal'"
          >
            <div
              class="text-500 w-full xl:w-5 font-bold overflow-hidden text-overflow-ellipsis"
            >
              Date de fin détaillant
            </div>

            <div class="text-900 w-full xl:w-7">
              <ng-container *ngIf="program.handlerEndDate; else notSet">
                {{ program.handlerEndDate | date : 'mediumDate' : '' : 'fr' }}
              </ng-container>
            </div>
          </li>

          <li
            class="flex align-items-center py-3 px-2 border-bottom-1 surface-border flex-wrap"
            *ngIf="program.type === 'recycling'"
          >
            <div
              class="text-500 w-full xl:w-5 font-bold overflow-hidden text-overflow-ellipsis"
            >
              Points gagnés par le parrain
            </div>

            <div class="text-900 w-full xl:w-7">
              {{ program.referrerPoints }}
            </div>
          </li>

          <li
            class="flex align-items-center py-3 px-2 border-bottom-1 surface-border flex-wrap"
            *ngIf="program.type === 'recycling'"
          >
            <div
              class="text-500 w-full xl:w-5 font-bold overflow-hidden text-overflow-ellipsis"
            >
              Points gagnés par le parrainé
            </div>

            <div class="text-900 w-full xl:w-7">
              {{ program.referredPoints }}
            </div>
          </li>

          <li
            class="flex align-items-center py-3 px-2 border-bottom-1 surface-border flex-wrap"
          >
            <div
              class="text-500 w-full xl:w-5 font-bold overflow-hidden text-overflow-ellipsis"
            >
              Nombre minimal d’emballages
            </div>

            <div class="text-900 w-full xl:w-7">
              {{ program.minimumProductQuantity }}
            </div>
          </li>

          <li
            class="flex align-items-center py-3 px-2 border-bottom-1 surface-border flex-wrap"
          >
            <div
              class="text-500 w-full xl:w-5 font-bold overflow-hidden text-overflow-ellipsis"
            >
              Nombre maximal d’emballages
            </div>

            <div class="text-900 w-full xl:w-7">
              <ng-container *ngIf="program.maximumProductQuantity; else notSet">
                {{ program.maximumProductQuantity }}
              </ng-container>
            </div>
          </li>

          <li
            class="flex align-items-center py-3 px-2 border-bottom-1 surface-border flex-wrap"
          >
            <div
              class="text-500 w-full xl:w-5 font-bold overflow-hidden text-overflow-ellipsis"
            >
              Nombre maximal de QR Codes pouvant être approuvés par User et par
              jour
            </div>

            <div class="text-900 w-full xl:w-7">
              <ng-container
                *ngIf="program.maximumUserBasketsPerDay; else notSet"
              >
                {{ program.maximumUserBasketsPerDay }}
              </ng-container>
            </div>
          </li>

          <li
            class="flex align-items-center py-3 px-2 surface-border flex-wrap"
          >
            <div
              class="text-500 w-full xl:w-4 font-bold overflow-hidden text-overflow-ellipsis"
            >
              Résumé
            </div>

            <div class="text-900 w-full xl:w-8">
              <div class="flex align-items-center mb-4">
                <img class="mr-2" src="/assets/flags/fr.svg" height="20" />

                <ng-container *ngIf="program.summaryFr; else notSet">{{
                  program.summaryFr
                }}</ng-container>
              </div>

              <div class="flex align-items-center">
                <img class="mr-2" src="/assets/flags/ma.svg" height="20" />

                <ng-container *ngIf="program.summaryAr; else notSet">{{
                  program.summaryAr
                }}</ng-container>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="card">
      <div class="flex align-items-center justify-content-between">
        <h3 class="text-2xl font-bold mb-0">Classement users</h3>

        <div>
          <button
            class="p-button-rounded p-button-text"
            type="button"
            pButton
            tabindex="-1"
            [icon]="'pi ' + (isRankingCardOpen ? 'pi-minus' : 'pi-plus')"
            (click)="isRankingCardOpen = !isRankingCardOpen"
          ></button>
        </div>
      </div>

      <div [@toggleCardContents]="isRankingCardOpen">
        <p class="mb-5" *ngIf="userRanking">
          Du {{ userRanking.start | date : 'mediumDate' : '' : 'fr' }} au
          {{ userRanking.end | date : 'mediumDate' : '' : 'fr' }}
        </p>

        <ul
          class="list-none p-0 m-0 flex-grow-1"
          *ngIf="userRanking !== undefined; else loadingSkeleton"
        >
          <li class="mb-4" *ngIf="userRanking && !userRanking.persons?.length">
            <span class="block text-500 font-medium mb-3 text-xl"
              >Aucun classement</span
            >
          </li>

          <ng-container *ngIf="userRanking; else notSet">
            <li
              class="flex align-items-center justify-content-between mb-4"
              *ngFor="let rankedPerson of userRanking.persons"
            >
              <div class="flex align-items-center">
                <div
                  class="text-xl text-900 font-bold mr-2 text-right"
                  style="width: 30px"
                >
                  {{ rankedPerson.currentRank }}
                </div>

                <p-avatar
                  [label]="rankedPerson.initials"
                  [style]="{
                    'background-color':
                      rankedPerson.nameToHslColors?.background,
                    color: rankedPerson.nameToHslColors?.contrast
                  }"
                  styleClass="mr-2"
                  *ngIf="rankedPerson.initials"
                ></p-avatar>

                <div class="text-xl text-900 font-medium">
                  {{ rankedPerson.firstName }} {{ rankedPerson.initial }}
                </div>
              </div>

              <div class="flex align-items-center">
                <div class="text-xl text-900 font-medium mr-2">
                  {{ rankedPerson.currentPeriodApprovedBasketCount }}
                </div>

                <i class="pi text-xl pi-qrcode"></i>
              </div>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>

    <div class="card">
      <div class="flex align-items-center justify-content-between">
        <h3 class="text-2xl font-bold mb-0">Impact énergie</h3>

        <div>
          <button
            class="p-button-rounded p-button-text"
            type="button"
            pButton
            tabindex="-1"
            [icon]="'pi ' + (isEnergyImpactCardOpen ? 'pi-minus' : 'pi-plus')"
            (click)="isEnergyImpactCardOpen = !isEnergyImpactCardOpen"
          ></button>
        </div>
      </div>

      <div [@toggleCardContents]="isEnergyImpactCardOpen">
        <ul
          class="list-none p-0 m-0 flex-grow-1"
          *ngIf="program; else loadingSkeleton"
        >
          <li
            class="flex align-items-center py-3 px-2 border-bottom-1 surface-border flex-wrap"
          >
            <div
              class="text-500 w-full xl:w-10 font-bold overflow-hidden text-overflow-ellipsis"
            >
              Kilo-joules économisés
            </div>

            <div class="text-900 w-full xl:w-2">
              {{ program.kiloJoulesSaved | number : '1.2-2' : 'fr' }}
            </div>
          </li>

          <li
            class="flex align-items-center py-3 px-2 surface-border flex-wrap"
          >
            <div
              class="text-500 w-full xl:w-10 font-bold overflow-hidden text-overflow-ellipsis"
            >
              Heures d'éclairage
            </div>

            <div class="text-900 w-full xl:w-2">
              {{ program.hoursOfLightingAllowed | number : '1.2-2' : 'fr' }}
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="card">
      <div class="flex align-items-center justify-content-between">
        <h3 class="text-2xl font-bold mb-0">Impact CO<sub>2</sub></h3>

        <div>
          <button
            class="p-button-rounded p-button-text"
            type="button"
            pButton
            tabindex="-1"
            [icon]="'pi ' + (isCo2ImpactCardOpen ? 'pi-minus' : 'pi-plus')"
            (click)="isCo2ImpactCardOpen = !isCo2ImpactCardOpen"
          ></button>
        </div>
      </div>

      <div [@toggleCardContents]="isCo2ImpactCardOpen">
        <ul
          class="list-none p-0 m-0 flex-grow-1"
          *ngIf="program; else loadingSkeleton"
        >
          <li
            class="flex align-items-center py-3 px-2 border-bottom-1 surface-border flex-wrap"
          >
            <div
              class="text-500 w-full xl:w-10 font-bold overflow-hidden text-overflow-ellipsis"
            >
              Grammes de CO<sub>2</sub> économisés
            </div>

            <div class="text-900 w-full xl:w-2">
              {{ program.gramsOfCo2Saved | number : '1.2-2' : 'fr' }}
            </div>
          </li>

          <li
            class="flex align-items-center py-3 px-2 surface-border flex-wrap"
          >
            <div
              class="text-500 w-full xl:w-10 font-bold overflow-hidden text-overflow-ellipsis"
            >
              Kilomètres parcourus
            </div>

            <div class="text-900 w-full xl:w-2">
              {{ program.metersAllowed / 1000 | number : '1.2-2' : 'fr' }}
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div id="icons">
      <div class="card" *ngIf="program; else loadingSkeleton">
        <div
          class="flex align-items-center justify-content-between"
          [ngClass]="{ 'mb-3': isIconsCardOpen }"
        >
          <h3 class="text-2xl font-bold mb-0">
            <ng-container *ngIf="icons; else miniSkeleton">{{
              icons.length
            }}</ng-container>
            icônes
          </h3>

          <div>
            <button
              class="p-button-rounded p-button-text"
              type="button"
              pButton
              tabindex="-1"
              [icon]="'pi ' + (isIconsCardOpen ? 'pi-minus' : 'pi-plus')"
              (click)="isIconsCardOpen = !isIconsCardOpen"
            ></button>
          </div>
        </div>

        <div [@toggleCardContents]="isIconsCardOpen">
          <p-messages [key]="'icons'"></p-messages>

          <p-table
            styleClass="p-datatable-gridlines"
            [currentPageReportTemplate]="
              'Enregistrements {first} à {last} sur un total de {totalRecords}'
            "
            [dataKey]="'code'"
            [loading]="isLoading"
            [responsiveLayout]="'scroll'"
            [rowHover]="true"
            [showCurrentPageReport]="true"
            [value]="icons"
            (onRowReorder)="onIconRowReorder($event)"
          >
            <ng-template pTemplate="body" let-icon let-index="rowIndex">
              <ng-container *ngLet="asIconDto(icon) as icon">
                <tr [pReorderableRow]="index" *ngIf="icon">
                  <td>
                    <span class="pi pi-bars" pReorderableRowHandle></span>
                  </td>

                  <td style="text-align: center">
                    <div
                      class="flex justify-content-center"
                      style="line-height: 0"
                    >
                      <p-image
                        class="shadow-4"
                        [preview]="true"
                        [src]="imageBaseUrl + icon.imageUrl"
                        width="100"
                        *ngIf="icon.imageUrl; else noImage"
                      ></p-image>
                    </div>
                  </td>

                  <td>
                    <div class="flex">
                      <button
                        class="p-button-rounded p-button-danger"
                        pButton
                        pRipple
                        icon="pi pi-trash"
                        (click)="deleteIcon(icon)"
                      ></button>
                    </div>
                  </td>
                </tr>
              </ng-container>
            </ng-template>

            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="3">Aucune icône trouvée.</td>
              </tr>
            </ng-template>

            <ng-template pTemplate="loadingbody">
              <tr>
                <td colspan="3">Chargement en cours. Veuillez patienter...</td>
              </tr>
            </ng-template>
          </p-table>

          <div class="mt-4" *ngIf="icons.length < 8">
            <p-fileUpload
              name="icons"
              accept="image/svg+xml"
              chooseIcon="pi pi-images"
              chooseLabel="Choisir des icônes"
              url="{{ iconsFileUploadUrl }}"
              [fileLimit]="8 - icons.length"
              [maxFileSize]="200 * 1024"
              [multiple]="true"
              (onError)="onUploadError($event)"
              (onProgress)="isLoading = true"
              (onUpload)="onIconUploadSuccess($event)"
            >
              <ng-template pTemplate="toolbar">
                <div class="py-3">
                  Les icônes sont <strong>facultatives.</strong>
                </div>

                <div class="py-3">
                  Les icônes doivent être au format <strong>SVG</strong> et
                  peser <strong>moins de 200 ko</strong> chacune.
                </div>

                <div class="py-3">
                  Il vous reste au maximum
                  <strong>{{ 8 - icons.length }} icônes</strong>
                  à télécharger
                </div>
              </ng-template>

              <ng-template pTemplate="content">
                <ul *ngIf="selectedIconFiles.length">
                  <li *ngFor="let file of selectedIconFiles">
                    {{ file.name }} - {{ file.size }} bytes
                  </li>
                </ul>
              </ng-template>
            </p-fileUpload>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 md:col-7">
    <div id="products">
      <div class="card">
        <div
          class="flex align-items-center justify-content-between"
          [ngClass]="{ 'mb-3': isProductsCardOpen }"
        >
          <h3 class="text-2xl font-bold mb-0">
            <ng-container *ngIf="products; else miniSkeleton">{{
              products.length
            }}</ng-container>
            produits
          </h3>

          <div>
            <button
              class="p-button-rounded p-button-text"
              type="button"
              pButton
              tabindex="-1"
              [icon]="'pi ' + (isProductsCardOpen ? 'pi-minus' : 'pi-plus')"
              (click)="isProductsCardOpen = !isProductsCardOpen"
              *ngIf="!isProductsCardMaximized"
            ></button>

            <button
              class="p-button-rounded p-button-text"
              type="button"
              pButton
              tabindex="-1"
              [icon]="
                'pi ' +
                (isProductsCardMaximized
                  ? 'pi-window-minimize'
                  : 'pi-window-maximize')
              "
              (click)="toggleProductsCardSize()"
              *ngIf="isProductsCardOpen"
            ></button>
          </div>
        </div>

        <div [@toggleCardContents]="isProductsCardOpen">
          <p-table
            styleClass="p-datatable-gridlines"
            [currentPageReportTemplate]="
              'Enregistrements {first} à {last} sur un total de {totalRecords}'
            "
            [dataKey]="'code'"
            [globalFilterFields]="['nameFr', 'nameAr']"
            [loading]="isLoading"
            [paginator]="true"
            [responsiveLayout]="'scroll'"
            [rowHover]="true"
            [rows]="10"
            [rowsPerPageOptions]="[10, 25, 50]"
            [scrollable]="true"
            [showCurrentPageReport]="true"
            [value]="products"
            (onRowReorder)="onProductRowReorder($event)"
            *ngIf="program; else loadingSkeleton"
          >
            <ng-template pTemplate="caption">
              <div class="flex justify-content-between flex-column sm:flex-row">
                <button
                  class="p-button-success sm:mb-2 mr-2"
                  pButton
                  pRipple
                  label="Créer un produit"
                  icon="pi pi-plus"
                  (click)="addNewProduct()"
                ></button>
              </div>

              <p-messages [key]="'products'"></p-messages>
            </ng-template>

            <ng-template pTemplate="header">
              <tr>
                <th *ngIf="isProductsCardMaximized"></th>

                <th></th>

                <th pSortableColumn="nameFr" pFrozenColumn>
                  <div class="flex justify-content-between align-items-center">
                    <div class="flex align-items-center gap-2">
                      <img src="assets/flags/fr.svg" height="20" />
                      Nom
                    </div>

                    <p-sortIcon field="nameFr"></p-sortIcon>
                  </div>
                </th>

                <th pSortableColumn="nameAr">
                  <div class="flex justify-content-between align-items-center">
                    <div class="flex align-items-center gap-2">
                      <img src="assets/flags/ma.svg" height="20" />
                      Nom
                    </div>

                    <p-sortIcon field="nameAr"></p-sortIcon>
                  </div>
                </th>

                <th
                  pSortableColumn="material.code"
                  *ngIf="isProductsCardMaximized"
                >
                  <div class="flex justify-content-between align-items-center">
                    Matériau

                    <p-sortIcon field="material.code"></p-sortIcon>
                  </div>
                </th>

                <th
                  pSortableColumn="type"
                  *ngIf="isProductsCardMaximized && program.type === 'goodDeal'"
                >
                  <div class="flex justify-content-between align-items-center">
                    Type

                    <p-sortIcon field="type"></p-sortIcon>
                  </div>
                </th>

                <th
                  pSortableColumn="mobilePoints"
                  *ngIf="isProductsCardMaximized && program.type !== 'goodDeal'"
                >
                  <div class="flex justify-content-between align-items-center">
                    Etoiles gagnées

                    <p-sortIcon field="mobilePoints"></p-sortIcon>
                  </div>
                </th>

                <th
                  pSortableColumn="weightInG"
                  *ngIf="isProductsCardMaximized && program.type !== 'goodDeal'"
                >
                  <div class="flex justify-content-between align-items-center">
                    Poids en grammes

                    <p-sortIcon field="weightInG"></p-sortIcon>
                  </div>
                </th>

                <th pSortableColumn="createdAt" *ngIf="isProductsCardMaximized">
                  <div class="flex justify-content-between align-items-center">
                    Création

                    <p-sortIcon field="createdAt"></p-sortIcon>
                  </div>
                </th>

                <th pSortableColumn="updatedAt" *ngIf="isProductsCardMaximized">
                  <div class="flex justify-content-between align-items-center">
                    Mise à jour

                    <p-sortIcon field="updatedAt"></p-sortIcon>
                  </div>
                </th>

                <th alignFrozen="right" pFrozenColumn></th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-product let-index="rowIndex">
              <ng-container *ngLet="asProductDto(product) as product">
                <tr [pReorderableRow]="index" *ngIf="product">
                  <td *ngIf="isProductsCardMaximized">
                    <span class="pi pi-bars" pReorderableRowHandle></span>
                  </td>

                  <td style="text-align: center">
                    <div
                      class="flex justify-content-center"
                      style="line-height: 0"
                    >
                      <p-image
                        class="shadow-4"
                        [preview]="true"
                        [src]="imageBaseUrl + product.imageUrl"
                        width="100"
                        *ngIf="product.imageUrl; else noImage"
                      ></p-image>
                    </div>
                  </td>

                  <td pFrozenColumn>
                    {{ product.nameFr }}
                  </td>

                  <td class="text-right" dir="rtl">
                    {{ product.nameAr }}
                  </td>

                  <td *ngIf="isProductsCardMaximized">
                    <ng-container *ngIf="product.material; else notSet">
                      <div
                        class="w-4rem h-4rem flex align-items-center justify-content-center m-auto"
                        [style.background-color]="'#' + product.material.color"
                      >
                        <p-avatar
                          [image]="imageBaseUrl + product.material.imageUrl"
                          *ngIf="product.material.imageUrl"
                        ></p-avatar>
                      </div>
                    </ng-container>
                  </td>

                  <td
                    class="text-right"
                    *ngIf="
                      isProductsCardMaximized && program.type === 'goodDeal'
                    "
                  >
                    <p-tag
                      [value]="
                        product.type === 'coupon' ? 'Coupon' : 'Recyclage'
                      "
                      [severity]="getProductTypeTagSeverity(product.type)"
                    ></p-tag>
                  </td>

                  <td
                    class="text-right"
                    *ngIf="
                      isProductsCardMaximized && program.type !== 'goodDeal'
                    "
                  >
                    {{ product.mobilePoints }}
                  </td>

                  <td
                    class="text-right"
                    *ngIf="
                      isProductsCardMaximized && program.type !== 'goodDeal'
                    "
                  >
                    {{ product.weightInG }}
                  </td>

                  <td *ngIf="isProductsCardMaximized">
                    {{ product.createdAt | date : 'long' : '' : 'fr' }}
                  </td>

                  <td *ngIf="isProductsCardMaximized">
                    {{ product.updatedAt | date : 'long' : '' : 'fr' }}
                  </td>

                  <td alignFrozen="right" pFrozenColumn>
                    <div class="flex">
                      <button
                        class="p-button-rounded p-button-warning mr-2"
                        pButton
                        pRipple
                        icon="pi pi-pencil"
                        (click)="editProduct(product)"
                      ></button>

                      <button
                        class="p-button-rounded p-button-danger"
                        pButton
                        pRipple
                        icon="pi pi-trash"
                        (click)="deleteProduct(product)"
                      ></button>
                    </div>
                  </td>
                </tr>
              </ng-container>
            </ng-template>

            <ng-template pTemplate="emptymessage">
              <tr>
                <td
                  [attr.colspan]="
                    isProductsCardMaximized
                      ? program.type === 'goodDeal'
                        ? 10
                        : 7
                      : 4
                  "
                >
                  Aucun produit trouvé.
                </td>
              </tr>
            </ng-template>

            <ng-template pTemplate="loadingbody">
              <tr>
                <td
                  [attr.colspan]="
                    isProductsCardMaximized
                      ? program.type === 'goodDeal'
                        ? 10
                        : 7
                      : 4
                  "
                >
                  Chargement en cours. Veuillez patienter...
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>

    <div id="gifts">
      <div class="card" *ngIf="program && program.type === 'recycling'">
        <div
          class="flex align-items-center justify-content-between"
          [ngClass]="{ 'mb-3': isGiftsCardOpen }"
        >
          <h3 class="text-2xl font-bold mb-0">
            <ng-container *ngIf="gifts; else miniSkeleton">{{
              gifts.length
            }}</ng-container>
            cadeaux
          </h3>

          <div>
            <button
              class="p-button-rounded p-button-text"
              type="button"
              pButton
              tabindex="-1"
              [icon]="'pi ' + (isGiftsCardOpen ? 'pi-minus' : 'pi-plus')"
              (click)="isGiftsCardOpen = !isGiftsCardOpen"
              *ngIf="!isGiftsCardMaximized"
            ></button>

            <button
              class="p-button-rounded p-button-text"
              type="button"
              pButton
              tabindex="-1"
              [icon]="
                'pi ' +
                (isGiftsCardMaximized
                  ? 'pi-window-minimize'
                  : 'pi-window-maximize')
              "
              (click)="toggleGiftsCardSize()"
              *ngIf="isGiftsCardOpen"
            ></button>
          </div>
        </div>

        <div [@toggleCardContents]="isGiftsCardOpen">
          <p-table
            styleClass="p-datatable-gridlines"
            [currentPageReportTemplate]="
              'Enregistrements {first} à {last} sur un total de {totalRecords}'
            "
            [dataKey]="'code'"
            [globalFilterFields]="['nameFr', 'nameAr']"
            [loading]="isLoading"
            [paginator]="true"
            [responsiveLayout]="'scroll'"
            [rowHover]="true"
            [rows]="10"
            [rowsPerPageOptions]="[10, 25, 50]"
            [scrollable]="true"
            [showCurrentPageReport]="true"
            [value]="gifts"
            (onRowReorder)="onGiftRowReorder($event)"
            *ngIf="program; else loadingSkeleton"
          >
            <ng-template pTemplate="caption">
              <div class="flex justify-content-between flex-column sm:flex-row">
                <button
                  class="p-button-success sm:mb-2 mr-2"
                  pButton
                  pRipple
                  label="Créer un cadeau"
                  icon="pi pi-plus"
                  (click)="addNewGift()"
                ></button>
              </div>

              <p-messages [key]="'gifts'"></p-messages>
            </ng-template>

            <ng-template pTemplate="header">
              <tr>
                <th *ngIf="isGiftsCardMaximized"></th>

                <th></th>

                <th pSortableColumn="nameFr" pFrozenColumn>
                  <div class="flex justify-content-between align-items-center">
                    <div class="flex align-items-center gap-2">
                      <img src="assets/flags/fr.svg" height="20" />
                      Nom
                    </div>

                    <p-sortIcon field="nameFr"></p-sortIcon>
                  </div>
                </th>

                <th pSortableColumn="nameAr">
                  <div class="flex justify-content-between align-items-center">
                    <div class="flex align-items-center gap-2">
                      <img src="assets/flags/ma.svg" height="20" />
                      Nom
                    </div>

                    <p-sortIcon field="nameAr"></p-sortIcon>
                  </div>
                </th>

                <th
                  pSortableColumn="restrictedToRole"
                  *ngIf="isGiftsCardMaximized"
                >
                  <div class="flex justify-content-between align-items-center">
                    Profil(s)

                    <p-sortIcon field="restrictedToRole"></p-sortIcon>
                  </div>
                </th>

                <th
                  pSortableColumn="allowedRedemptions"
                  *ngIf="isGiftsCardMaximized"
                >
                  <div class="flex justify-content-between align-items-center">
                    Nombre max d’échanges

                    <p-sortIcon field="allowedRedemptions"></p-sortIcon>
                  </div>
                </th>

                <th pSortableColumn="type" *ngIf="isGiftsCardMaximized">
                  <div class="flex justify-content-between align-items-center">
                    Méthode d’échange

                    <p-sortIcon field="type"></p-sortIcon>
                  </div>
                </th>

                <th pSortableColumn="mobilePoints" *ngIf="isGiftsCardMaximized">
                  <div class="flex justify-content-between align-items-center">
                    Valeur en étoiles pour l’utilisateur

                    <p-sortIcon field="mobilePoints"></p-sortIcon>
                  </div>
                </th>

                <th
                  pSortableColumn="handlerPoints"
                  *ngIf="isGiftsCardMaximized"
                >
                  <div class="flex justify-content-between align-items-center">
                    Valeur en étoiles pour le détaillant

                    <p-sortIcon field="handlerPoints"></p-sortIcon>
                  </div>
                </th>

                <th pSortableColumn="createdAt" *ngIf="isGiftsCardMaximized">
                  <div class="flex justify-content-between align-items-center">
                    Création

                    <p-sortIcon field="createdAt"></p-sortIcon>
                  </div>
                </th>

                <th pSortableColumn="updatedAt" *ngIf="isGiftsCardMaximized">
                  <div class="flex justify-content-between align-items-center">
                    Mise à jour

                    <p-sortIcon field="updatedAt"></p-sortIcon>
                  </div>
                </th>

                <th alignFrozen="right" pFrozenColumn></th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-gift let-index="rowIndex">
              <ng-container *ngLet="asGiftDto(gift) as gift">
                <tr [pReorderableRow]="index" *ngIf="gift">
                  <td *ngIf="isGiftsCardMaximized">
                    <span class="pi pi-bars" pReorderableRowHandle></span>
                  </td>

                  <td style="text-align: center">
                    <div
                      class="flex justify-content-center"
                      style="line-height: 0"
                    >
                      <p-image
                        class="shadow-4"
                        [preview]="true"
                        [src]="imageBaseUrl + gift.imageUrl"
                        width="100"
                        *ngIf="gift.imageUrl; else noImage"
                      ></p-image>
                    </div>
                  </td>

                  <td pFrozenColumn>
                    {{ gift.nameFr }}
                  </td>

                  <td class="text-right" dir="rtl">
                    {{ gift.nameAr }}
                  </td>

                  <td class="text-center" *ngIf="isGiftsCardMaximized">
                    <span
                      class="tlb--status-badge"
                      [ngClass]="getRoleStyleClass(gift.restrictedToRole)"
                      *ngIf="gift.restrictedToRole; else notSet"
                    >
                      {{ gift.restrictedToRole }}
                    </span>
                  </td>

                  <td class="text-right" *ngIf="isGiftsCardMaximized">
                    <span *ngIf="gift.allowedRedemptions; else unlimited">
                      {{ gift.allowedRedemptions }}
                    </span>
                  </td>

                  <td class="text-center" *ngIf="isGiftsCardMaximized">
                    <p-tag
                      [value]="
                        gift.type === 'form' ? 'Formulaire' : 'QR&nbsp;Code'
                      "
                      [ngClass]="'mb-2'"
                      [severity]="gift.type === 'form' ? 'warning' : 'success'"
                    ></p-tag>
                  </td>

                  <td class="text-right" *ngIf="isGiftsCardMaximized">
                    {{ gift.mobilePoints }}
                  </td>

                  <td class="text-right" *ngIf="isGiftsCardMaximized">
                    {{ gift.handlerPoints }}
                  </td>

                  <td *ngIf="isGiftsCardMaximized">
                    {{ gift.createdAt | date : 'long' : '' : 'fr' }}
                  </td>

                  <td *ngIf="isGiftsCardMaximized">
                    {{ gift.updatedAt | date : 'long' : '' : 'fr' }}
                  </td>

                  <td alignFrozen="right" pFrozenColumn>
                    <div class="flex">
                      <button
                        class="p-button-rounded p-button-warning mr-2"
                        pButton
                        pRipple
                        icon="pi pi-pencil"
                        (click)="editGift(gift)"
                      ></button>

                      <button
                        class="p-button-rounded p-button-info mr-2"
                        pButton
                        pRipple
                        icon="pi pi-align-left"
                        (click)="editGiftFormDefinition(gift)"
                        *ngIf="gift.type === 'form'"
                      ></button>

                      <button
                        class="p-button-rounded p-button-danger"
                        pButton
                        pRipple
                        icon="pi pi-trash"
                        (click)="deleteGift(gift)"
                      ></button>
                    </div>
                  </td>
                </tr>
              </ng-container>
            </ng-template>

            <ng-template pTemplate="emptymessage">
              <tr>
                <td [attr.colspan]="isGiftsCardMaximized ? 12 : 4">
                  Aucun cadeau trouvé.
                </td>
              </tr>
            </ng-template>

            <ng-template pTemplate="loadingbody">
              <tr>
                <td [attr.colspan]="isGiftsCardMaximized ? 12 : 4">
                  Chargement en cours. Veuillez patienter...
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>

    <div id="handlers">
      <div class="card">
        <div
          class="flex align-items-center justify-content-between"
          [ngClass]="{ 'mb-3': isHandlersCardOpen }"
        >
          <h3 class="text-2xl font-bold mb-0">
            <ng-container *ngIf="handlers; else miniSkeleton">{{
              handlerRecordCount
            }}</ng-container>
            détaillants
          </h3>

          <div>
            <button
              class="p-button-rounded p-button-text"
              type="button"
              pButton
              tabindex="-1"
              [icon]="'pi ' + (isHandlersCardOpen ? 'pi-minus' : 'pi-plus')"
              (click)="isHandlersCardOpen = !isHandlersCardOpen"
              *ngIf="!isHandlersCardMaximized"
            ></button>

            <button
              class="p-button-rounded p-button-text"
              type="button"
              pButton
              tabindex="-1"
              [icon]="
                'pi ' +
                (isHandlersCardMaximized
                  ? 'pi-window-minimize'
                  : 'pi-window-maximize')
              "
              (click)="toggleHandlersCardSize()"
              *ngIf="isHandlersCardOpen"
            ></button>
          </div>
        </div>

        <div [@toggleCardContents]="isHandlersCardOpen">
          <p-table
            #handlerIndexDataTable
            styleClass="p-datatable-gridlines"
            [currentPageReportTemplate]="
              'Enregistrements {first} à {last} sur un total de ' +
              handlerRecordCount.toLocaleString()
            "
            [dataKey]="'code'"
            [lazy]="true"
            [loading]="isHandlersDataLoading"
            [paginator]="true"
            [responsiveLayout]="'scroll'"
            [rowHover]="true"
            [rows]="apiPageSize"
            [rowsPerPageOptions]="[10, 25, 50]"
            [scrollable]="true"
            [showCurrentPageReport]="true"
            [totalRecords]="handlerRecordCount"
            [value]="handlers"
            (onLazyLoad)="fetchNextHandlerPage($event)"
            *ngIf="program; else loadingSkeleton"
          >
            <ng-template pTemplate="caption">
              <div class="flex justify-content-between flex-column sm:flex-row">
                <p-splitButton
                  [disabled]="isLoading"
                  [icon]="handlerMenuItems[0].icon!!"
                  [label]="handlerMenuItems[0].label!!"
                  [menuStyleClass]="'w-15rem'"
                  [model]="handlerMenuItems"
                  (onClick)="handlerMenuItems[0].command!!()"
                  *ngIf="
                    program.type === 'pro' ||
                      program.type === 'goodDeal' ||
                      (program.type === 'recycling' &&
                        program.collectionType === 'deferred');
                    else importButton
                  "
                ></p-splitButton>

                <ng-template #importButton>
                  <button
                    class="sm:mb-2 mr-2"
                    pButton
                    pRipple
                    label="Importer détaillants"
                    icon="pi pi-map-marker"
                    (click)="uploadHandlers()"
                  ></button>
                </ng-template>
              </div>

              <p-messages [key]="'handlers'"></p-messages>
            </ng-template>

            <ng-template pTemplate="header">
              <tr>
                <th></th>

                <th
                  style="min-width: 20rem"
                  pSortableColumn="code"
                  pFrozenColumn
                >
                  <div class="flex justify-content-between align-items-center">
                    Code

                    <p-sortIcon field="code"></p-sortIcon>
                  </div>
                </th>

                <th style="min-width: 15rem" pSortableColumn="level">
                  <div class="flex justify-content-between align-items-center">
                    Niveau

                    <p-sortIcon field="level"></p-sortIcon>
                  </div>
                </th>

                <th style="min-width: 20rem" pSortableColumn="nameFr">
                  <div class="flex justify-content-between align-items-center">
                    <div class="flex align-items-center gap-2">
                      <img src="assets/flags/fr.svg" height="20" />
                      Nom
                    </div>

                    <p-sortIcon field="nameFr"></p-sortIcon>
                  </div>
                </th>

                <th
                  style="min-width: 20rem"
                  pSortableColumn="nameAr"
                  *ngIf="isHandlersCardMaximized"
                >
                  <div class="flex justify-content-between align-items-center">
                    <div class="flex align-items-center gap-2">
                      <img src="assets/flags/ma.svg" height="20" />
                      Nom
                    </div>

                    <p-sortIcon field="nameAr"></p-sortIcon>
                  </div>
                </th>

                <th
                  style="min-width: 20rem"
                  pSortableColumn="addressFr"
                  *ngIf="isHandlersCardMaximized"
                >
                  <div class="flex justify-content-between align-items-center">
                    <div class="flex align-items-center gap-2">
                      <img src="assets/flags/fr.svg" height="20" />
                      Adresse
                    </div>

                    <p-sortIcon field="addressFr"></p-sortIcon>
                  </div>
                </th>

                <th
                  style="min-width: 20rem"
                  pSortableColumn="addressAr"
                  *ngIf="isHandlersCardMaximized"
                >
                  <div class="flex justify-content-between align-items-center">
                    <div class="flex align-items-center gap-2">
                      <img src="assets/flags/ma.svg" height="20" />
                      Adresse
                    </div>

                    <p-sortIcon field="addressAr"></p-sortIcon>
                  </div>
                </th>

                <th
                  style="min-width: 20rem"
                  pSortableColumn="phone"
                  *ngIf="isHandlersCardMaximized"
                >
                  <div class="flex justify-content-between align-items-center">
                    N° de téléphone

                    <p-sortIcon field="phone"></p-sortIcon>
                  </div>
                </th>

                <th
                  style="min-width: 20rem"
                  pSortableColumn="latitude"
                  *ngIf="isHandlersCardMaximized"
                >
                  <div class="flex justify-content-between align-items-center">
                    Latitude

                    <p-sortIcon field="latitude"></p-sortIcon>
                  </div>
                </th>

                <th
                  style="min-width: 20rem"
                  pSortableColumn="longitude"
                  *ngIf="isHandlersCardMaximized"
                >
                  <div class="flex justify-content-between align-items-center">
                    Longitude

                    <p-sortIcon field="longitude"></p-sortIcon>
                  </div>
                </th>

                <th alignFrozen="right" pFrozenColumn></th>
              </tr>

              <tr>
                <th></th>

                <th pFrozenColumn>
                  <p-columnFilter type="text" field="code"></p-columnFilter>
                </th>

                <th>
                  <p-columnFilter type="numeric" field="level"></p-columnFilter>
                </th>

                <th>
                  <p-columnFilter type="text" field="nameFr"></p-columnFilter>
                </th>

                <th *ngIf="isHandlersCardMaximized">
                  <p-columnFilter type="text" field="nameAr"></p-columnFilter>
                </th>

                <th *ngIf="isHandlersCardMaximized">
                  <p-columnFilter
                    type="text"
                    field="addressFr"
                  ></p-columnFilter>
                </th>

                <th *ngIf="isHandlersCardMaximized">
                  <p-columnFilter
                    type="text"
                    field="addressAr"
                  ></p-columnFilter>
                </th>

                <th *ngIf="isHandlersCardMaximized">
                  <p-columnFilter type="text" field="phone"></p-columnFilter>
                </th>

                <th *ngIf="isHandlersCardMaximized">
                  <p-columnFilter type="text" field="latitude"></p-columnFilter>
                </th>

                <th *ngIf="isHandlersCardMaximized">
                  <p-columnFilter
                    type="text"
                    field="longitude"
                  ></p-columnFilter>
                </th>

                <th alignFrozen="right" pFrozenColumn></th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-handler>
              <ng-container *ngLet="asHandlerDto(handler) as handler">
                <tr *ngIf="handler">
                  <td style="text-align: center">
                    <div class="flex" style="line-height: 0">
                      <p-image
                        class="shadow-4"
                        src="{{ handler.clonedPhotoUrl ?? handler.photoUrl }}"
                        [preview]="true"
                        width="100"
                        *ngIf="
                          handler.clonedPhotoUrl || handler.photoUrl;
                          else noImage
                        "
                      ></p-image>
                    </div>
                  </td>

                  <td pFrozenColumn>
                    <p-tag [value]="handler.code"></p-tag>
                  </td>

                  <td>
                    {{ handler.level }}
                  </td>

                  <td>
                    {{ handler.nameFr }}
                  </td>

                  <td
                    class="text-right"
                    dir="rtl"
                    *ngIf="isHandlersCardMaximized"
                  >
                    <ng-container *ngIf="handler.nameAr; else notSet">{{
                      handler.nameAr
                    }}</ng-container>
                  </td>

                  <td *ngIf="isHandlersCardMaximized">
                    <ng-container *ngIf="handler.addressFr; else notSet">{{
                      handler.addressFr
                    }}</ng-container>
                  </td>

                  <td
                    class="text-right"
                    dir="rtl"
                    *ngIf="isHandlersCardMaximized"
                  >
                    <ng-container *ngIf="handler.addressAr; else notSet">{{
                      handler.addressAr
                    }}</ng-container>
                  </td>

                  <td *ngIf="isHandlersCardMaximized">
                    <ng-container *ngIf="handler.phone; else notSet">
                      {{ handler.phone }}
                    </ng-container>
                  </td>

                  <td class="text-right" *ngIf="isHandlersCardMaximized">
                    <ng-container *ngIf="handler.latitude; else notSet">
                      {{ handler.latitude }}
                    </ng-container>
                  </td>

                  <td class="text-right" *ngIf="isHandlersCardMaximized">
                    <ng-container *ngIf="handler.longitude; else notSet">
                      {{ handler.longitude }}
                    </ng-container>
                  </td>

                  <td alignFrozen="right" pFrozenColumn>
                    <button
                      class="p-button-rounded p-button-help mb-2"
                      type="button"
                      pButton
                      pRipple
                      icon="pi pi-qrcode"
                      (click)="showHandlerQrCode(handler)"
                      *ngIf="
                        (program.type === 'pro' ||
                          program.type === 'goodDeal' ||
                          (program.type === 'recycling' &&
                            program.collectionType === 'deferred')) &&
                        handler.level === 1
                      "
                    ></button>

                    <button
                      class="p-button-rounded"
                      type="button"
                      pButton
                      icon="pi pi-search"
                      [routerLink]="'/handlers/' + handler.code"
                    ></button>
                  </td>
                </tr>
              </ng-container>
            </ng-template>

            <ng-template pTemplate="emptymessage">
              <tr>
                <td [attr.colspan]="isHandlersCardMaximized ? 11 : 5">
                  Aucun détaillant trouvé.
                </td>
              </tr>
            </ng-template>

            <ng-template pTemplate="loadingbody">
              <tr>
                <td [attr.colspan]="isHandlersCardMaximized ? 11 : 5">
                  Chargement en cours. Veuillez patienter...
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="col-12" *ngIf="segment === 'details'">
  <div class="grid" *ngIf="program; else loadingSkeleton">
    <div class="col-12 md:col-6">
      <div class="card">
        <div class="flex align-items-center justify-content-between mb-3">
          <div class="flex align-items-center">
            <img class="mr-4" src="assets/flags/fr.svg" height="20" />

            <h3 class="text-2xl font-bold my-0">Vidéo User en français</h3>
          </div>
        </div>

        <div *ngIf="safeVideoUrls.mobile.fr; else notSet">
          <div class="relative" style="padding-top: 56.25%">
            <iframe
              class="border-0 absolute left-0 top-0 w-full h-full"
              allowfullscreen
              frameborder="0"
              loading="lazy"
              [src]="safeVideoUrls.mobile.fr"
            ></iframe>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 md:col-6">
      <div class="card">
        <div class="flex align-items-center justify-content-between mb-3">
          <div class="flex align-items-center">
            <img class="mr-4" src="assets/flags/ma.svg" height="20" />

            <h3 class="text-2xl font-bold my-0">Vidéo User en arabe</h3>
          </div>
        </div>

        <div *ngIf="safeVideoUrls.mobile.ar; else notSet">
          <div class="relative" style="padding-top: 56.25%">
            <iframe
              class="border-0 absolute left-0 top-0 w-full h-full"
              allowfullscreen
              frameborder="0"
              loading="lazy"
              [src]="safeVideoUrls.mobile.ar"
            ></iframe>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 md:col-6">
      <div class="card">
        <div class="flex align-items-center justify-content-between mb-3">
          <div class="flex align-items-center">
            <img class="mr-4" src="assets/flags/fr.svg" height="20" />

            <h3 class="text-2xl font-bold my-0">Vidéo Handler en français</h3>
          </div>
        </div>

        <div *ngIf="safeVideoUrls.handler.fr; else notSet">
          <div class="relative" style="padding-top: 56.25%">
            <iframe
              class="border-0 absolute left-0 top-0 w-full h-full"
              allowfullscreen
              frameborder="0"
              loading="lazy"
              [src]="safeVideoUrls.handler.fr"
            ></iframe>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 md:col-6">
      <div class="card">
        <div class="flex align-items-center justify-content-between mb-3">
          <div class="flex align-items-center">
            <img class="mr-4" src="assets/flags/ma.svg" height="20" />

            <h3 class="text-2xl font-bold my-0">Vidéo Handler en arabe</h3>
          </div>
        </div>

        <div *ngIf="safeVideoUrls.handler.ar; else notSet">
          <div class="relative" style="padding-top: 56.25%">
            <iframe
              class="border-0 absolute left-0 top-0 w-full h-full"
              allowfullscreen
              frameborder="0"
              loading="lazy"
              [src]="safeVideoUrls.handler.ar"
            ></iframe>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 md:col-6">
      <div class="card">
        <div
          class="flex align-items-center justify-content-between"
          [ngClass]="{ 'mb-3': isDescriptionFrCardOpen }"
        >
          <div class="flex align-items-center">
            <img class="mr-4" src="assets/flags/fr.svg" height="20" />

            <h3 class="text-2xl font-bold my-0">Description en français</h3>
          </div>

          <div>
            <button
              class="p-button-rounded p-button-text"
              type="button"
              pButton
              tabindex="-1"
              [icon]="
                'pi ' + (isDescriptionFrCardOpen ? 'pi-minus' : 'pi-plus')
              "
              (click)="isDescriptionFrCardOpen = !isDescriptionFrCardOpen"
            ></button>
          </div>
        </div>

        <div [@toggleCardContents]="isDescriptionFrCardOpen">
          <div class="p-editor-container">
            <div
              class="p-editor-content ql-snow"
              style="padding: 12px 15px"
              [innerHTML]="program.descriptionFr"
              *ngIf="program.descriptionFr; else notSet"
            ></div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 md:col-6">
      <div class="card">
        <div
          class="flex align-items-center justify-content-between"
          [ngClass]="{ 'mb-3': isDescriptionArCardOpen }"
        >
          <div class="flex align-items-center">
            <img class="mr-4" src="assets/flags/ma.svg" height="20" />

            <h3 class="text-2xl font-bold my-0">Description en arabe</h3>
          </div>

          <div>
            <button
              class="p-button-rounded p-button-text"
              type="button"
              pButton
              tabindex="-1"
              [icon]="
                'pi ' + (isDescriptionArCardOpen ? 'pi-minus' : 'pi-plus')
              "
              (click)="isDescriptionArCardOpen = !isDescriptionArCardOpen"
            ></button>
          </div>
        </div>

        <div [@toggleCardContents]="isDescriptionArCardOpen">
          <div class="p-editor-container">
            <div
              class="p-editor-content ql-snow"
              style="padding: 12px 15px"
              [innerHTML]="program.descriptionAr"
              dir="rtl"
              *ngIf="program.descriptionAr; else notSet"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #notSet>
  <span class="text-pink-600 font-italic">Non défini</span>
</ng-template>

<ng-template #unlimited>
  <span class="text-blue-600 font-italic">Illimité</span>
</ng-template>

<div
  class="card"
  [ngClass]="{ 'tlb--max-screen-height': segment === 'map' }"
  *ngIf="segment === 'map'"
>
  <ng-container *ngIf="googleMapsApiLoaded | async">
    <google-map
      [options]="options"
      (mapInitialized)="onMapInitialized($event)"
      (idle)="fetchHandlers()"
    ></google-map>
  </ng-container>

  <p-confirmPopup></p-confirmPopup>
</div>

<p-dialog
  class="p-fluid"
  header="Lien d’affiliation à communiquer aux users"
  [modal]="true"
  [resizable]="false"
  [style]="{ 'min-width': '320px' }"
  [(visible)]="isProAffiliationLinkDialogVisible"
>
  <ng-template pTemplate="content">
    <div
      class="text-center"
      *ngIf="program && program.type === 'pro' && proAffiliationLink"
    >
      <div class="p-inputgroup">
        <input
          class="readonly-input"
          pInputText
          [value]="proAffiliationLink"
          [readonly]="true"
        />

        <button
          type="button"
          pButton
          (click)="copyProAffiliationLinkToClipboard()"
        >
          <i class="pi pi-copy"></i>
        </button>
      </div>

      <p-messages [key]="'proAffiliationLink'"></p-messages>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <button
      class="p-button-text"
      pButton
      pRipple
      label="Fermer"
      [loading]="isLoading"
      (click)="isProAffiliationLinkDialogVisible = false"
    ></button>
  </ng-template>
</p-dialog>

<p-dialog
  class="p-fluid"
  [modal]="true"
  [resizable]="false"
  [style]="{ 'min-width': '320px' }"
  [(visible)]="isHandlerQrCodeDialogVisible"
  (onHide)="onHandlerQrCodeDialogHidden()"
>
  <ng-template pTemplate="header">
    <div style="width: 100%" *ngIf="qrCodePageRecordCount; else singleQrCode">
      <div class="p-dialog-title p-2">
        Génération du PDF global des QR codes compacteurs... Pour annuler,
        cliquer sur la croix &rarr;
      </div>
    </div>

    <ng-template #singleQrCode>
      <div class="p-dialog-title p-2">QR code compacteur</div>
    </ng-template>
  </ng-template>

  <ng-template pTemplate="content">
    <p-progressBar
      [value]="
        (100 * qrCodePageRecordIndex) / qrCodePageRecordCount | number : '1.0-0'
      "
      *ngIf="qrCodePageRecordCount"
    ></p-progressBar>

    <div
      class="text-center"
      #handlerDownloadableQrCode
      *ngIf="program && selectedHandler && selectedHandler.level === 1"
    >
      <qrcode
        [cssClass]="'text-center tlb--qr-code'"
        [errorCorrectionLevel]="'M'"
        [imageSrc]="'./assets/images/ui/logo-positive-213x213.png'"
        [imageHeight]="75 * handlerQrCodeSizeMultiplier"
        [imageWidth]="75 * handlerQrCodeSizeMultiplier"
        [qrdata]="program.code + '__' + selectedHandler.code"
        [width]="352 * handlerQrCodeSizeMultiplier"
      ></qrcode>

      <h2
        class="m-0 pb-6"
        [style.padding-top]="
          handlerQrCodeSizeMultiplier > 1 ? '4rem !important' : 'unset'
        "
        [style.transform]="'scale(' + handlerQrCodeSizeMultiplier + ')'"
      >
        <b>{{ selectedHandler.code }}</b>
      </h2>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <button
      class="p-button-text p-button-info"
      pButton
      pRipple
      icon="pi pi-download"
      label="Télécharger"
      [loading]="isLoading"
      (click)="downloadHandlerQrCode()"
    ></button>

    <button
      class="p-button-text"
      pButton
      pRipple
      label="Fermer"
      [loading]="isLoading"
      (click)="isHandlerQrCodeDialogVisible = false"
    ></button>
  </ng-template>
</p-dialog>

<p-dialog
  class="p-fluid"
  header="Importer un fichier de détaillants"
  [modal]="true"
  [resizable]="false"
  [style]="{ 'min-width': '320px' }"
  [(visible)]="isHandlersDialogVisible"
>
  <ng-template pTemplate="content">
    <div class="text-center">
      <p-fileUpload
        name="file"
        mode="basic"
        chooseIcon="pi pi-file-excel"
        chooseLabel="Choisir un fichier Excel (500 ko max.)"
        accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        [auto]="true"
        [maxFileSize]="500 * 1024"
        [url]="handlerFileUploadUrl"
        (onError)="onHandlerUploadError($event)"
        (onProgress)="isLoading = true"
        (onUpload)="onHandlerUploadSuccess($event)"
      ></p-fileUpload>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <button
      class="p-button-text"
      pButton
      pRipple
      label="Fermer"
      [loading]="isLoading"
      (click)="isHandlersDialogVisible = false"
    ></button>
  </ng-template>
</p-dialog>

<p-dialog
  class="p-fluid"
  [header]="giftDialogHeader"
  [maximizable]="true"
  [modal]="true"
  [resizable]="false"
  [style]="{ width: 'min(100vw, 960px)' }"
  [(visible)]="isGiftDialogVisible"
  *ngIf="program && program.type !== 'goodDeal'"
>
  <ng-template pTemplate="content">
    <form class="w-full md:w-10 mx-auto" [formGroup]="giftForm">
      <div class="mb-4">
        <div class="text-center">
          <div class="inline-block my-4 shadow-4" style="line-height: 0">
            <p-image
              [preview]="true"
              [src]="croppedImageSrc"
              width="320"
              *ngIf="croppedImageSrc || (croppedFiles && imageFilename)"
            ></p-image>
          </div>

          <p-fileUpload
            name="file"
            #fileUploader
            accept="image/*"
            chooseIcon="pi pi-image"
            chooseLabel="Choisir une image"
            mode="basic"
            [files]="croppedFiles || []"
            [maxFileSize]="500 * 1024"
            [url]="giftIllustrationUploadUrl"
            (onError)="onUploadError($event)"
            (onProgress)="isLoading = true"
            (onSelect)="onSelect($event)"
            (onUpload)="onGiftUploadSuccess($event)"
          ></p-fileUpload>

          <div>
            <be-green--ui--input-error
              [class]="'justify-content-center font-bold p-error mt-2'"
              [control]="giftFormControls.imageUrl"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>
      </div>

      <div class="flex align-items-start mb-4 mt-6 gap-8">
        <div class="w-4">
          <label
            class="block text-900 text-xl font-medium mb-2"
            for="restrictedToRole"
          >
            Profil(s)
          </label>

          <p-selectButton
            class="w-full mb-3"
            id="restrictedToRole"
            [options]="roles"
            [multiple]="true"
            formControlName="restrictedToRole"
          ></p-selectButton>

          <div class="mt-3">
            <be-green--ui--input-error
              [control]="giftFormControls.restrictedToRole"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>

        <div class="w-4">
          <label class="block text-900 text-xl font-medium mb-2" for="type">
            Méthode d’échange
          </label>

          <p-selectButton
            class="w-full mb-3"
            id="type"
            [options]="giftTypes"
            formControlName="type"
          ></p-selectButton>

          <div class="mt-3">
            <be-green--ui--input-error
              [control]="giftFormControls.type"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>

        <div class="w-4">
          <label
            class="block text-900 text-xl font-medium mb-2"
            for="allowedRedemptions"
          >
            Nombre max d’échanges
          </label>

          <input
            class="w-full mb-3"
            id="allowedRedemptions"
            (keyup.enter)="saveGift()"
            formControlName="allowedRedemptions"
            pInputText
            [pKeyFilter]="'int'"
            inputmode="numeric"
            min="1"
          />

          <p>Facultatif (vide = illimité)</p>

          <div>
            <be-green--ui--input-error
              [control]="giftFormControls.allowedRedemptions"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>
      </div>

      <div class="flex align-items-start mb-4 gap-8">
        <div class="w-6">
          <label
            class="block text-900 text-xl font-medium mb-2 flex align-items-center"
            for="nameFr"
          >
            <img class="mr-2" src="assets/flags/fr.svg" height="20" />
            Nom en français
          </label>

          <input
            class="w-full mb-3"
            id="nameFr"
            (keyup.enter)="saveGift()"
            formControlName="nameFr"
            pInputText
            style="padding: 1rem"
            maxlength="140"
          />

          <p>
            {{ 140 - giftFormControls.nameFr.value?.length }}
            caractères restants.
          </p>

          <div>
            <be-green--ui--input-error
              [control]="giftFormControls.nameFr"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>

        <div class="w-6">
          <label
            class="block text-900 text-xl font-medium mb-2 flex align-items-center"
            for="nameAr"
          >
            <img class="mr-2" src="assets/flags/ma.svg" height="20" />
            Nom en arabe
          </label>

          <input
            class="w-full mb-3"
            id="nameAr"
            dir="rtl"
            (keyup.enter)="saveGift()"
            formControlName="nameAr"
            pInputText
            style="padding: 1rem"
            maxlength="140"
          />

          <p>
            {{ 140 - giftFormControls.nameAr.value?.length }}
            caractères restants.
          </p>

          <div>
            <be-green--ui--input-error
              [control]="giftFormControls.nameAr"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>
      </div>

      <div class="flex align-items-start mb-4 gap-8">
        <div class="w-6">
          <label
            class="block text-900 text-xl font-medium mb-2"
            for="mobilePoints"
            >Valeur en étoiles pour l’utilisateur</label
          >
          <input
            class="mb-3"
            id="mobilePoints"
            (keyup.enter)="saveGift()"
            formControlName="mobilePoints"
            pInputText
            [pKeyFilter]="'int'"
            inputmode="numeric"
            style="padding: 1rem"
            min="1"
          />

          <div>
            <be-green--ui--input-error
              [control]="giftFormControls.mobilePoints"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>

        <div class="w-6">
          <label
            class="block text-900 text-xl font-medium mb-2"
            for="handlerPoints"
            >Valeur en étoiles pour le détaillant</label
          >
          <input
            class="mb-3"
            id="handlerPoints"
            (keyup.enter)="saveGift()"
            formControlName="handlerPoints"
            pInputText
            [pKeyFilter]="'int'"
            inputmode="numeric"
            style="padding: 1rem"
            min="1"
          />

          <div>
            <be-green--ui--input-error
              [control]="giftFormControls.handlerPoints"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template pTemplate="footer">
    <button
      class="p-button-text p-button-danger"
      icon="pi pi-times"
      label="Annuler"
      pButton
      pRipple
      [loading]="isLoading"
      (click)="isGiftDialogVisible = false"
    ></button>

    <button
      class="p-button-text p-button-success"
      icon="pi pi-check"
      label="Enregistrer"
      pButton
      pRipple
      [loading]="isLoading"
      (click)="saveGift()"
    ></button>
  </ng-template>
</p-dialog>

<p-dialog
  class="p-fluid"
  [header]="giftFormDefinitionDialogHeader"
  [maximizable]="true"
  [modal]="true"
  [resizable]="false"
  [style]="{ width: 'min(100vw, 640px)' }"
  [(visible)]="isGiftFormDefinitionDialogVisible"
  *ngIf="program && program.type !== 'goodDeal' && gift && gift.type === 'form'"
>
  <ng-template pTemplate="content">
    <div class="w-full md:w-10 mx-auto" *ngIf="giftFormDefinition">
      <div class="mb-4">
        <div class="flex align-items-center gap-2 pt-2">
          <p-inputSwitch
            id="firstAndLastName"
            [(ngModel)]="giftFormDefinition.firstAndLastName"
          ></p-inputSwitch>

          <label
            class="block text-900 text-xl font-medium"
            for="firstAndLastName"
          >
            Nom et prénom
          </label>
        </div>
      </div>

      <div class="mb-4">
        <div class="flex align-items-center gap-2 pt-2">
          <p-inputSwitch
            id="telecomOperators"
            [(ngModel)]="giftFormDefinition.telecomOperators.enabled"
          ></p-inputSwitch>

          <label
            class="block text-900 text-xl font-medium"
            for="telecomOperators"
          >
            Opérateur télécoms
          </label>
        </div>

        <p-multiSelect
          [disabled]="!giftFormDefinition.telecomOperators.enabled"
          [display]="'chip'"
          [options]="telecomOperators"
          [(ngModel)]="giftFormDefinition.telecomOperators.selected"
        >
          <ng-template let-telecomOperator pTemplate="item">
            <div>
              {{ 'GENERIC.TELECOM_OPERATOR.' + telecomOperator | translate }}
            </div>
          </ng-template>
        </p-multiSelect>
      </div>

      <div class="mb-4">
        <div class="flex align-items-center gap-2 pt-2">
          <p-inputSwitch
            id="mobilePhone"
            [(ngModel)]="giftFormDefinition.mobilePhone"
          ></p-inputSwitch>

          <label class="block text-900 text-xl font-medium" for="mobilePhone">
            Numéro de téléphone mobile
          </label>
        </div>
      </div>

      <div class="mb-4">
        <div class="flex align-items-center gap-2 pt-2">
          <p-inputSwitch
            id="topUpTypes"
            [(ngModel)]="giftFormDefinition.topUpTypes.enabled"
          ></p-inputSwitch>

          <label class="block text-900 text-xl font-medium" for="topUpTypes">
            Type de recharge
          </label>
        </div>

        <p-multiSelect
          [disabled]="!giftFormDefinition.topUpTypes.enabled"
          [display]="'chip'"
          [options]="topUpTypes"
          [(ngModel)]="giftFormDefinition.topUpTypes.selected"
        >
          <ng-template let-topUpType pTemplate="item">
            <div>{{ 'GENERIC.TOP_UP_TYPE.' + topUpType | translate }}</div>
          </ng-template>
        </p-multiSelect>
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <button
      class="p-button-text p-button-danger"
      icon="pi pi-times"
      label="Annuler"
      pButton
      pRipple
      [loading]="isLoading"
      (click)="isGiftFormDefinitionDialogVisible = false"
    ></button>

    <button
      class="p-button-text p-button-success"
      icon="pi pi-check"
      label="Enregistrer"
      pButton
      pRipple
      [loading]="isLoading"
      (click)="saveGiftFormDefinition()"
    ></button>
  </ng-template>
</p-dialog>

<p-dialog
  class="p-fluid"
  [header]="productDialogHeader"
  [maximizable]="true"
  [modal]="true"
  [resizable]="false"
  [style.width]="
    'min(100vw, ' + (program?.type === 'goodDeal' ? '960px' : '640px') + ')'
  "
  [(visible)]="isProductDialogVisible"
>
  <ng-template pTemplate="content">
    <form
      class="w-full"
      [ngClass]="{ 'md:w-10 mx-auto': program.type !== 'goodDeal' }"
      [formGroup]="productForm"
      *ngIf="program; else loadingSkeleton"
    >
      <div class="mb-4">
        <div class="text-center">
          <div class="inline-block my-4 shadow-4" style="line-height: 0">
            <p-image
              [preview]="true"
              [src]="croppedImageSrc"
              width="320"
              *ngIf="croppedImageSrc || (croppedFiles && imageFilename)"
            ></p-image>
          </div>

          <p-fileUpload
            name="file"
            #fileUploader
            accept="image/*"
            chooseIcon="pi pi-image"
            chooseLabel="Choisir une image"
            mode="basic"
            [files]="croppedFiles || []"
            [maxFileSize]="500 * 1024"
            [url]="productIllustrationUploadUrl"
            (onError)="onUploadError($event)"
            (onProgress)="isLoading = true"
            (onSelect)="onSelect($event)"
            (onUpload)="onProductUploadSuccess($event)"
          ></p-fileUpload>

          <div>
            <be-green--ui--input-error
              [class]="'justify-content-center font-bold p-error mt-2'"
              [control]="productFormControls.imageUrl"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>
      </div>

      <div class="flex align-items-start mb-4 gap-8">
        <div class="w-4" *ngIf="program.type === 'goodDeal'">
          <label class="block text-900 text-xl font-medium mb-2" for="type"
            >Type</label
          >

          <div class="inline-block mb-3">
            <p-selectButton
              id="type"
              [options]="productTypes"
              formControlName="type"
            ></p-selectButton>
          </div>

          <div>
            <be-green--ui--input-error
              [control]="productFormControls.type"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>

        <div
          [ngClass]="{
            'w-4': program.type === 'goodDeal',
            'w-6': program.type !== 'goodDeal'
          }"
        >
          <label
            class="block text-900 text-xl font-medium mb-2 flex align-items-center"
            for="nameFr"
          >
            <img class="mr-2" src="assets/flags/fr.svg" height="20" />
            Nom en français
          </label>

          <input
            class="w-full mb-3"
            id="nameFr"
            (keyup.enter)="saveProduct()"
            formControlName="nameFr"
            pInputText
            style="padding: 1rem"
            maxlength="140"
          />

          <p>
            {{ 140 - productFormControls.nameFr.value?.length }}
            caractères restants.
          </p>

          <div>
            <be-green--ui--input-error
              [control]="productFormControls.nameFr"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>

        <div
          [ngClass]="{
            'w-4': program.type === 'goodDeal',
            'w-6': program.type !== 'goodDeal'
          }"
        >
          <label
            class="block text-900 text-xl font-medium mb-2 flex align-items-center"
            for="nameAr"
          >
            <img class="mr-2" src="assets/flags/ma.svg" height="20" />
            Nom en arabe
          </label>

          <input
            class="w-full mb-3"
            id="nameAr"
            dir="rtl"
            (keyup.enter)="saveProduct()"
            formControlName="nameAr"
            pInputText
            style="padding: 1rem"
            maxlength="140"
          />

          <p>
            {{ 140 - productFormControls.nameAr.value?.length }}
            caractères restants.
          </p>

          <div>
            <be-green--ui--input-error
              [control]="productFormControls.nameAr"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>
      </div>

      <div
        class="flex align-items-start mb-4 gap-8"
        *ngIf="productFormControls.type.value === 'recycling'"
      >
        <div class="w-6">
          <label class="block text-900 text-xl font-medium mb-2" for="material"
            >Matériau</label
          >

          <p-dropdown
            formControlName="material"
            optionLabel="nameFr"
            optionValue="code"
            placeholder="Choisir un matériau"
            styleClass="w-full mb-3 tlb--p-dropdown-padding-1rem"
            [options]="materials"
            [showClear]="true"
            *ngIf="materials"
          ></p-dropdown>

          <div>
            <be-green--ui--input-error
              [control]="productFormControls.material"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>

        <div class="w-6">
          <label class="block text-900 text-xl font-medium mb-2" for="weightInG"
            >Poids en grammes</label
          >
          <input
            class="mb-3"
            id="weightInG"
            (keyup.enter)="saveProduct()"
            formControlName="weightInG"
            pInputText
            [pKeyFilter]="'int'"
            inputmode="numeric"
            style="padding: 1rem"
            min="1"
          />

          <div>
            <be-green--ui--input-error
              [control]="productFormControls.weightInG"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>
      </div>

      <div
        class="flex align-items-start mb-4 gap-8"
        *ngIf="program.type !== 'goodDeal'"
      >
        <div class="w-6">
          <label
            class="block text-900 text-xl font-medium mb-2"
            for="mobilePoints"
            >Etoiles gagnées</label
          >
          <input
            class="mb-3"
            id="mobilePoints"
            (keyup.enter)="saveProduct()"
            formControlName="mobilePoints"
            pInputText
            [pKeyFilter]="'int'"
            inputmode="numeric"
            style="padding: 1rem"
            min="1"
          />

          <div>
            <be-green--ui--input-error
              [control]="productFormControls.mobilePoints"
              platform="web"
            ></be-green--ui--input-error>
          </div>
        </div>

        <div class="w-6"></div>
      </div>
    </form>
  </ng-template>

  <ng-template pTemplate="footer">
    <button
      class="p-button-text p-button-danger"
      icon="pi pi-times"
      label="Annuler"
      pButton
      pRipple
      [loading]="isLoading"
      (click)="isProductDialogVisible = false"
    ></button>

    <button
      class="p-button-text p-button-success"
      icon="pi pi-check"
      label="Enregistrer"
      pButton
      pRipple
      [loading]="isLoading"
      (click)="saveProduct()"
    ></button>
  </ng-template>
</p-dialog>

<p-dialog
  header="Confirmation"
  [modal]="true"
  [resizable]="false"
  [style]="{ width: '450px' }"
  [(visible)]="isDeleteGiftDialogVisible"
  *ngIf="program && program.type !== 'goodDeal'"
>
  <div class="flex align-items-center">
    <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
    <span *ngIf="gift"
      >Voulez-vous supprimer le cadeau <b>{{ gift.nameFr }}</b> ?</span
    >
  </div>

  <ng-template pTemplate="footer">
    <button
      class="p-button-text"
      pButton
      pRipple
      icon="pi pi-times"
      label="Non"
      (click)="isDeleteGiftDialogVisible = false"
    ></button>

    <button
      class="p-button-text p-button-danger"
      pButton
      pRipple
      icon="pi pi-check"
      label="Oui"
      [loading]="isLoading"
      (click)="confirmDeleteGift()"
    ></button>
  </ng-template>
</p-dialog>

<p-dialog
  header="Confirmation"
  [modal]="true"
  [resizable]="false"
  [style]="{ width: '450px' }"
  [(visible)]="isDeleteIconDialogVisible"
>
  <div class="flex align-items-center">
    <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
    <span *ngIf="icon">Voulez-vous supprimer l’icône ?</span>
  </div>

  <ng-template pTemplate="footer">
    <button
      class="p-button-text"
      pButton
      pRipple
      icon="pi pi-times"
      label="Non"
      (click)="isDeleteIconDialogVisible = false"
    ></button>

    <button
      class="p-button-text p-button-danger"
      pButton
      pRipple
      icon="pi pi-check"
      label="Oui"
      [loading]="isLoading"
      (click)="confirmDeleteIcon()"
    ></button>
  </ng-template>
</p-dialog>

<p-dialog
  header="Confirmation"
  [modal]="true"
  [resizable]="false"
  [style]="{ width: '450px' }"
  [(visible)]="isDeleteProductDialogVisible"
>
  <div class="flex align-items-center">
    <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
    <span *ngIf="product"
      >Voulez-vous supprimer le produit <b>{{ product.nameFr }}</b> ?</span
    >
  </div>

  <ng-template pTemplate="footer">
    <button
      class="p-button-text"
      pButton
      pRipple
      icon="pi pi-times"
      label="Non"
      (click)="isDeleteProductDialogVisible = false"
    ></button>

    <button
      class="p-button-text p-button-danger"
      pButton
      pRipple
      icon="pi pi-check"
      label="Oui"
      [loading]="isLoading"
      (click)="confirmDeleteProduct()"
    ></button>
  </ng-template>
</p-dialog>

<ng-template #noImage>
  <img
    class="shadow-4"
    src="assets/layout/images/empty-300x240.jpg"
    width="100"
  />
</ng-template>

<p-dialog
  header="Confirmation"
  [breakpoints]="{ '960px': '50vw', '640px': '85vw' }"
  [modal]="true"
  [resizable]="false"
  [style]="{ width: '450px' }"
  [(visible)]="isConfirmDialogVisible"
>
  <div class="flex align-items-center justify-content-center">
    <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>

    <div>
      <span [innerHTML]="confirmationDialogMessage"></span>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <button
      class="p-button-text"
      pButton
      pRipple
      icon="pi pi-times"
      label="Non"
      (click)="isConfirmDialogVisible = false"
    ></button>

    <button
      class="p-button-text p-button-danger"
      pButton
      pRipple
      icon="pi pi-check"
      label="Oui"
      [loading]="isLoading"
      (click)="confirm()"
    ></button>
  </ng-template>
</p-dialog>

<p-dialog
  class="p-fluid"
  header="Recadrer l’image (vers un ratio de 3/2)"
  [modal]="true"
  [resizable]="false"
  [style]="{ width: 'min(100vw, 640px)' }"
  [(visible)]="isCropperDialogVisible"
>
  <ng-template pTemplate="content">
    <image-cropper
      #imageCropper
      [aspectRatio]="3 / 2"
      [autoCrop]="false"
      [canvasRotation]="imageRotation"
      [imageBase64]="selectedImageData"
      [maintainAspectRatio]="true"
      [onlyScaleDown]="true"
      [resizeToWidth]="700"
      [transform]="imageTransforms"
      (cropperReady)="onCropperReady($event)"
      (imageCropped)="onImageCropped($event)"
      (loadImageFailed)="onLoadImageFailed()"
    ></image-cropper>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="grid" *ngIf="isCropperReady">
      <div class="w-3 text-left">
        <button
          class="p-button-text p-button-danger"
          icon="pi pi-times"
          label="Annuler"
          pButton
          pRipple
          [loading]="isLoading"
          (click)="cancelImageCropModal()"
        ></button>
      </div>

      <div class="w-6 text-center">
        <p-button
          icon="pi pi-replay"
          styleClass="p-button-outlined p-button-sm"
          [loading]="isLoading"
          (click)="rotateLeft()"
        ></p-button>

        <p-button
          icon="pi pi-refresh"
          styleClass="p-button-outlined p-button-sm"
          [loading]="isLoading"
          (click)="rotateRight()"
        ></p-button>

        <p-button
          icon="pi pi-arrows-h"
          styleClass="p-button-outlined p-button-sm"
          [loading]="isLoading"
          (click)="flipHorizontal()"
        ></p-button>

        <p-button
          icon="pi pi-arrows-v"
          styleClass="p-button-outlined p-button-sm"
          [loading]="isLoading"
          (click)="flipVertical()"
        ></p-button>

        <p-button
          icon="pi pi-search-minus"
          styleClass="p-button-outlined p-button-sm"
          [disabled]="imageScale <= imageScaleMin"
          [loading]="isLoading"
          (click)="zoomOut()"
        ></p-button>

        <p-button
          icon="pi pi-search-plus"
          styleClass="p-button-outlined p-button-sm"
          [disabled]="imageScale >= imageScaleMax"
          [loading]="isLoading"
          (click)="zoomIn()"
        ></p-button>
      </div>

      <div class="w-3 text-right">
        <button
          class="p-button-text p-button-success"
          icon="pi pi-check"
          label="Enregistrer"
          pButton
          pRipple
          [loading]="isLoading"
          (click)="cropAndUploadImage()"
        ></button>
      </div>
    </div>
  </ng-template>
</p-dialog>

<ng-template #miniSkeleton>
  <p-skeleton width="2rem"></p-skeleton>
</ng-template>
