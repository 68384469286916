<p-toolbar styleClass="mb-4 bg-white">
  <div class="p-toolbar-group-start">
    <div class="flex gap-2">
      <p-chip
        *ngFor="let filter of activeFilters"
        [label]="filter.value"
        [removable]="true"
        (onRemove)="removeFilter(filter.field)"
      >
        <span
          class="font-bold text-primary mr-2"
          [pTooltip]="filter.matchMode || '?'"
          >{{ filter.symbol }}</span
        >
      </p-chip>
    </div>
  </div>

  <div class="p-toolbar-group-end">
    <button
      class="p-button-help ml-2"
      icon="pi pi-upload"
      label="Export"
      pButton
      pRipple
      [disabled]="isLoading"
      (click)="export()"
    ></button>
  </div>
</p-toolbar>

<p-messages></p-messages>

<div class="card">
  <p-table
    styleClass="p-datatable-gridlines"
    [currentPageReportTemplate]="
      'Enregistrements {first} à {last} sur un total de  ' +
      recordCount.toLocaleString()
    "
    [dataKey]="'code'"
    [filters]="filters"
    [first]="first"
    [lazy]="true"
    [loading]="isLoading"
    [paginator]="true"
    [responsiveLayout]="'scroll'"
    [rowHover]="true"
    [rows]="rows"
    [rowsPerPageOptions]="[10, 25, 50]"
    [scrollable]="true"
    [showCurrentPageReport]="true"
    [sortField]="sortField"
    [sortOrder]="sortOrder"
    [totalRecords]="recordCount"
    [value]="baskets"
    (onLazyLoad)="onLazyLoadEvent($event)"
  >
    <ng-template pTemplate="header">
      <tr>
        <th
          style="min-width: 20rem"
          pSortableColumn="participation.program.nameFr"
          pFrozenColumn
        >
          <div class="flex justify-content-between align-items-center">
            Programme

            <p-sortIcon field="participation.program.nameFr"></p-sortIcon>
          </div>
        </th>

        <th pSortableColumn="participation.program.type">
          <div class="flex justify-content-between align-items-center">
            Type

            <p-sortIcon field="participation.program.type"></p-sortIcon>
          </div>
        </th>

        <th pSortableColumn="participation.program.collectionType">
          <div class="flex justify-content-between align-items-center">
            Collecte

            <p-sortIcon
              field="participation.program.collectionType"
            ></p-sortIcon>
          </div>
        </th>

        <th
          style="min-width: 20rem"
          pSortableColumn="participation.person.firstName, participation.person.lastName"
        >
          <div class="flex justify-content-between align-items-center">
            Participant

            <p-sortIcon
              field="participation.person.firstName, participation.person.lastName"
            ></p-sortIcon>
          </div>
        </th>

        <th pSortableColumn="status.code">
          <div class="flex justify-content-between align-items-center">
            État

            <p-sortIcon field="status.code"></p-sortIcon>
          </div>
        </th>

        <th style="min-width: 16rem" pSortableColumn="updated_at">
          <div class="flex justify-content-between align-items-center">
            Date état

            <p-sortIcon field="updated_at"></p-sortIcon>
          </div>
        </th>

        <th style="min-width: 15rem" pSortableColumn="expectedPoints">
          <div class="flex justify-content-between align-items-center">
            ⭐ attendues

            <p-sortIcon field="expectedPoints"></p-sortIcon>
          </div>
        </th>

        <th style="min-width: 15rem" pSortableColumn="validatedPoints">
          <div class="flex justify-content-between align-items-center">
            ⭐ confirmées

            <p-sortIcon field="validatedPoints"></p-sortIcon>
          </div>
        </th>

        <th style="min-width: 15rem" pSortableColumn="quality.code">
          <div class="flex justify-content-between align-items-center">
            Qualité

            <p-sortIcon field="quality.code"></p-sortIcon>
          </div>
        </th>

        <th alignFrozen="right" pFrozenColumn></th>
      </tr>

      <tr>
        <th pFrozenColumn>
          <p-columnFilter type="text" field="participation.program.nameFr">
          </p-columnFilter>
        </th>

        <th>
          <p-columnFilter
            field="participation.program.type"
            matchMode="equals"
            [showMenu]="false"
          >
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback"
            >
              <p-dropdown
                placeholder="Tous"
                [appendTo]="'body'"
                [ngModel]="value"
                [options]="programTypes"
                [showClear]="true"
                (onChange)="filter($event.value)"
              >
                <ng-template let-option pTemplate="item">
                  <p-tag
                    [value]="option.label"
                    [severity]="getProgramTypeTagSeverity(option.value)"
                  ></p-tag>
                </ng-template>
              </p-dropdown>
            </ng-template>
          </p-columnFilter>
        </th>

        <th>
          <p-columnFilter
            field="participation.program.collectionType"
            matchMode="equals"
            [showMenu]="false"
          >
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback"
            >
              <p-dropdown
                placeholder="Toutes"
                [appendTo]="'body'"
                [ngModel]="value"
                [options]="collectionTypes"
                [showClear]="true"
                (onChange)="filter($event.value)"
              >
                <ng-template let-option pTemplate="item">
                  <p-tag
                    [value]="option.label"
                    [severity]="getCollectionTypeTagSeverity(option.value)"
                  ></p-tag>
                </ng-template>
              </p-dropdown> </ng-template
          ></p-columnFilter>
        </th>

        <th>
          <p-columnFilter
            type="text"
            field="participation.person.firstName, participation.person.lastName"
          ></p-columnFilter>
        </th>

        <th>
          <p-columnFilter
            field="status.code"
            matchMode="equals"
            [showMenu]="false"
          >
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback"
            >
              <p-dropdown
                placeholder="Tous"
                [appendTo]="'body'"
                [ngModel]="value"
                [options]="statuses"
                [showClear]="true"
                (onChange)="filter($event.value)"
              >
                <ng-template let-option pTemplate="item">
                  <p-tag
                    [value]="option.label"
                    [severity]="getStatusTagSeverity(option.value)"
                  ></p-tag>
                </ng-template>
              </p-dropdown>
            </ng-template>
          </p-columnFilter>
        </th>

        <th>
          <p-columnFilter
            type="date"
            appendTo="body"
            field="updated_at"
          ></p-columnFilter>
        </th>

        <th>
          <p-columnFilter
            type="numeric"
            field="expectedPoints"
          ></p-columnFilter>
        </th>

        <th>
          <p-columnFilter
            type="numeric"
            field="validatedPoints"
          ></p-columnFilter>
        </th>

        <th>
          <p-columnFilter
            field="quality.code"
            matchMode="equals"
            [showMenu]="false"
          >
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback"
            >
              <p-dropdown
                placeholder="Toutes"
                [appendTo]="'body'"
                [ngModel]="value"
                [options]="qualities"
                [showClear]="true"
                (onChange)="filter($event.value)"
              >
                <ng-template let-option pTemplate="item">
                  <be-green--ui--mdi-emoticon
                    [type]="option.value"
                  ></be-green--ui--mdi-emoticon>
                </ng-template>
              </p-dropdown>
            </ng-template>
          </p-columnFilter>
        </th>

        <th alignFrozen="right" pFrozenColumn></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-basket>
      <ng-container *ngLet="asBasketDto(basket) as basket">
        <tr *ngIf="basket">
          <td pFrozenColumn>
            <a [routerLink]="'/programs/' + basket.participation.program.code">
              {{ basket.participation.program.nameFr }}

              <i class="pi pi-external-link"></i>
            </a>
          </td>

          <td>
            <p-tag
              [value]="
                basket.participation.program.type === 'goodDeal'
                  ? 'Bon plan'
                  : basket.participation.program.type === 'pro'
                  ? 'Pro'
                  : 'Recyclage'
              "
              [severity]="
                getProgramTypeTagSeverity(basket.participation.program.type)
              "
            ></p-tag>

            <p
              *ngIf="
                basket.participation.program.type === 'pro' &&
                basket.participation.program.organizationType
              "
            >
              <p-tag
                [value]="basket.participation.program.organizationType.nameFr"
                severity="info"
              ></p-tag>
            </p>
          </td>

          <td>
            <p-tag
              [value]="
                basket.participation.program.collectionType === 'realTime'
                  ? 'Temps réel'
                  : 'Différée'
              "
              [severity]="
                getCollectionTypeTagSeverity(
                  basket.participation.program.collectionType
                )
              "
              *ngIf="
                basket.participation.program.type === 'recycling';
                else notSet
              "
            ></p-tag>
          </td>

          <td>
            {{ basket.participation.person.firstName }}
            {{ basket.participation.person.lastName }}
          </td>

          <td>
            <p-tag
              [value]="basket.status.name"
              [severity]="getStatusTagSeverity(basket.status.code)"
              *ngIf="basket.status"
            ></p-tag>
          </td>

          <td>
            {{ basket.updatedAt | date : 'medium' : undefined : 'fr' }}
          </td>

          <td class="text-right">
            {{ basket.expectedPoints | number : '1.0-0' : 'fr' }}
          </td>

          <td class="text-right">
            <ng-container *ngIf="basket.validatedPoints !== null; else notSet">
              <p-tag
                [value]="
                  (basket.validatedPoints | number : '1.0-0' : 'fr') || '0'
                "
                severity="success"
              ></p-tag>
            </ng-container>
          </td>

          <td class="text-center">
            <ng-container *ngIf="basket.quality; else notSet">
              <be-green--ui--mdi-emoticon
                [type]="basket.quality.code"
              ></be-green--ui--mdi-emoticon>
            </ng-container>
          </td>

          <td alignFrozen="right" pFrozenColumn>
            <button
              class="p-button-rounded"
              type="button"
              pButton
              icon="pi pi-search"
              [routerLink]="'/baskets/' + basket.code"
            ></button>
          </td>
        </tr>
      </ng-container>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="8">Aucun QR code trouvé.</td>
      </tr>
    </ng-template>

    <ng-template pTemplate="loadingbody">
      <tr>
        <td colspan="8">Chargement en cours. Veuillez patienter...</td>
      </tr>
    </ng-template>
  </p-table>
</div>

<ng-template #notSet>
  <span class="text-pink-600 font-italic">Non défini</span>
</ng-template>
