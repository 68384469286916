import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { HandlerApi } from '@be-green/api-services';
import { HandlerDto, ValueLabelDto } from '@be-green/dto';
import { ErrorService, SeoService, UtilsService } from '@be-green/ui-services';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { format } from 'date-fns';
import {
  ConfirmationService,
  LazyLoadEvent,
  MenuItem,
  MessageService,
} from 'primeng/api';
import { Table } from 'primeng/table';
import { Observable, catchError, map, of } from 'rxjs';
import { LayoutService } from '../../layout/layout.service';

@Component({
  selector: 'be-green--admin--handlers-index',
  templateUrl: './handlers-index.component.html',
  styleUrls: ['./handlers-index.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HandlersIndexComponent implements OnInit {
  @ViewChild('handlerIndexDataTable') handlerIndexDataTable!: Table;

  actionMenuItems: MenuItem[] = [
    {
      label: 'Import détaillants',
      icon: 'pi pi-map-marker',
      command: () => {
        this.uploadHandlers();
      },
    },
    {
      label: 'Import itinéraires',
      icon: 'pi pi-map',
      command: () => {
        this.uploadItineraries();
      },
    },
  ];
  googleMapsApiLoaded?: Observable<boolean>;
  handlers!: HandlerDto[];
  handlerFileUploadUrl = `/handlers/upload/handlers`;
  isLoading = false;
  isHandlersDialogVisible = false;
  isItinerariesDialogVisible = false;
  isMapLoadedTriggered = false;
  itineraryFileUploadUrl = `/handlers/upload/itineraries`;
  map?: google.maps.Map;
  mapHandlers?: HandlerDto[];
  markerCluster?: MarkerClusterer;
  markerOptions: google.maps.MarkerOptions = { draggable: false };
  markers: google.maps.Marker[] = [];
  options: google.maps.MapOptions = {
    center: {
      // Casablanca
      lat: 33.5722678,
      lng: -7.6570322,
    },

    styles: [
      {
        featureType: 'administrative.country',
        stylers: [{ visibility: 'off' }],
      },

      {
        featureType: 'poi',
        stylers: [{ visibility: 'off' }],
      },
    ],

    zoom: 11,
  };
  recordCount = 0;
  segment: 'list' | 'map' = 'list';
  segments: ValueLabelDto[] = [
    { label: 'Liste', value: 'list' },
    { label: 'Carte', value: 'map' },
  ];

  constructor(
    @Inject('API_PAGE_SIZE') readonly apiPageSize: number,
    @Inject('GOOGLE_MAPS_API_KEY') readonly googleMapsApiKey: string,
    private confirmationService: ConfirmationService,
    private readonly errorService: ErrorService,
    private readonly handlerApi: HandlerApi,
    private readonly httpClient: HttpClient,
    private readonly layoutService: LayoutService,
    private readonly messageService: MessageService,
    private readonly router: Router,
    private readonly seoService: SeoService,
  ) {
    this.layoutService.registerBreadcrumbs([{ label: 'Détaillants' }]);
    this.seoService.setTitle('Détaillants - Admin - Be Green');
  }

  ngOnInit(): void {
    this.isLoading = true;

    this.googleMapsApiLoaded = this.httpClient
      .jsonp(
        `https://maps.googleapis.com/maps/api/js?key=${this.googleMapsApiKey}`,
        'callback',
      )
      .pipe(
        map(() => true),
        catchError(() => of(false)),
      );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  asHandlerDto(handler: any): HandlerDto {
    return handler as HandlerDto;
  }

  eventTargetValue(event: Event) {
    return (event.target as HTMLInputElement).value;
  }

  export() {
    this.isLoading = true;

    this.handlerApi.export().subscribe({
      next: async (blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        document.body.appendChild(link);
        link.setAttribute('style', 'display: none');
        link.href = url;

        const filename = UtilsService.getFilenameFromResponseHeaders(blob);
        link.download =
          filename ??
          `handlers--${format(new Date(), 'yyyy-MM-dd_HH-mm-ss')}.xlsx`;

        link.click();
        window.URL.revokeObjectURL(url);
        link.remove();

        this.isLoading = false;
      },

      error: async (error: HttpErrorResponse) => {
        const { title, message } = await this.errorService.handleError(error);

        this.isLoading = false;

        this.messageService.add({
          detail: message,
          key: 'handlers',
          severity: 'error',
          summary: title,
        });
      },
    });
  }

  async fetchHandlers() {
    const bounds = this.map?.getBounds();

    if (bounds) {
      this.handlerApi
        .getAllInBounds(
          bounds?.getNorthEast().lat() as number,
          bounds?.getNorthEast().lng() as number,
          bounds?.getSouthWest().lat() as number,
          bounds?.getSouthWest().lng() as number,
        )
        .subscribe((handlers) => {
          this.mapHandlers = handlers;

          for (const handler of handlers) {
            if (
              !this.markers?.find(
                (marker) => marker.getTitle() === handler?.code,
              )
            ) {
              const marker = new google.maps.Marker({
                position: {
                  lat: handler.latitude as number,
                  lng: handler.longitude as number,
                },

                icon: `assets/images/ui/marker.png`,
                title: handler.code,
              });

              this.markers.push(marker);

              marker.addListener(
                'click',
                (event: google.maps.MapMouseEvent) => {
                  event.stop();
                  this.onMarkerClick(event, marker);
                },
              );
            }
          }

          this.markerCluster?.clearMarkers();
          this.markerCluster = new MarkerClusterer({
            map: this.map,
            markers: this.markers,
          });
        });
    }
  }

  fetchNextPage(lazyLoadEvent: LazyLoadEvent) {
    this.isLoading = true;

    this.handlerApi
      .getAll(
        UtilsService.fromLazyLoadEventToQueryUiDto(
          lazyLoadEvent,
          this.apiPageSize,
        ),
      )
      .subscribe({
        next: (data) => {
          this.handlers = data.rows;
          this.recordCount = data.count;

          this.isLoading = false;
        },

        error: (error: HttpErrorResponse) => {
          this.errorService.handleError(error);

          this.isLoading = false;
        },
      });
  }

  onMapInitialized(map: google.maps.Map) {
    this.map = map;
  }

  onMarkerClick(
    event: google.maps.MapMouseEvent,
    clickedMarker: google.maps.Marker,
  ) {
    const marker = this.mapHandlers?.find(
      (addedMarker) => addedMarker.code === clickedMarker.getTitle(),
    );

    if (!marker) {
      return;
    }

    this.confirmationService.confirm({
      acceptLabel: 'Détails',
      message: `${marker.nameFr} (${marker.code})`,
      icon: 'pi pi-search',
      rejectVisible: false,
      target: event.domEvent.target as EventTarget,

      accept: () => {
        this.router.navigate(['handlers', marker.code]);
      },
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUploadError(event: { files: File[]; error: any }) {
    this.messageService.add({
      severity: 'error',
      summary: event.error.error?.reason,
      detail: event.error.error?.message,
    });

    this.isLoading = false;
    this.isHandlersDialogVisible = false;
    this.isItinerariesDialogVisible = false;
  }

  onUploadHandlersSuccess(event: {
    files: File[];
    originalEvent: HttpResponse<{
      url: string;
      warnings?: {
        ignoredLines: { line: number; phone: string }[];
        modifiedPhones: {
          line: number;
          existingPhone: string;
          newPhone: string;
        }[];
        ignoredSheets: string[];
        invalidCodes: { line: number; code: string }[];
        invalidLatitudes: { line: number; latitude: string }[];
        invalidLevels: { line: number; level: string }[];
        invalidLongitudes: { line: number; longitude: string }[];
        invalidNames: { line: number; name: string }[];
        invalidPhones: { line: number; phone: string }[];
      };
    }>;
  }) {
    if (
      event.originalEvent.body?.warnings &&
      (event.originalEvent.body.warnings.ignoredLines.length ||
        event.originalEvent.body.warnings.ignoredSheets.length ||
        event.originalEvent.body.warnings.invalidCodes.length ||
        event.originalEvent.body.warnings.invalidLatitudes.length ||
        event.originalEvent.body.warnings.invalidLevels.length ||
        event.originalEvent.body.warnings.invalidLongitudes.length ||
        event.originalEvent.body.warnings.invalidNames.length ||
        event.originalEvent.body.warnings.invalidPhones.length)
    ) {
      if (event.originalEvent.body.warnings.ignoredLines.length) {
        this.messageService.add({
          detail: `L’importation du fichier « ${
            event.files[0].name
          } » est terminée. Toutefois, ces numéros de mobile détaillants sont déjà présents dans la base et les lignes indiquées ont été ignorées : ** ${event.originalEvent.body.warnings.ignoredLines
            .map(
              (ignoredLine) =>
                `${ignoredLine.phone} à la ligne ${ignoredLine.line}`,
            )
            .join(' * , * ')} **`,
          severity: 'warn',
          summary: 'Importation complétée',
        });
      }

      if (event.originalEvent.body.warnings.ignoredSheets.length) {
        this.messageService.add({
          detail: `L’importation du fichier « ${
            event.files[0].name
          } » est terminée. Toutefois, une seule feuille peut être importée et les feuilles suivantes ont été ignorées : ** ${event.originalEvent.body.warnings.ignoredSheets.join(
            ' * , * ',
          )} **`,
          severity: 'warn',
          summary: 'Importation complétée',
        });
      }

      if (event.originalEvent.body.warnings.invalidCodes.length) {
        this.messageService.add({
          detail: `L’importation du fichier « ${
            event.files[0].name
          } » est terminée. Toutefois, ces détaillants n’ont pas été enregistrés dans la base car leurs codes ne sont pas valides : ** ${event.originalEvent.body.warnings.invalidCodes
            .map(
              (invalidCode) =>
                `"${invalidCode.code}" à la ligne ${invalidCode.line}`,
            )
            .join(' * , * ')} **`,
          severity: 'warn',
          summary: 'Importation complétée',
        });
      }

      if (event.originalEvent.body.warnings.invalidLatitudes.length) {
        this.messageService.add({
          detail: `L’importation du fichier « ${
            event.files[0].name
          } » est terminée. Toutefois, ces détaillants n’ont pas été enregistrés dans la base car leurs latitudes ne sont pas valides : ** ${event.originalEvent.body.warnings.invalidLatitudes
            .map(
              (invalidLatitude) =>
                `"${invalidLatitude.latitude}" à la ligne ${invalidLatitude.line}`,
            )
            .join(' * , * ')} **`,
          severity: 'warn',
          summary: 'Importation complétée',
        });
      }

      if (event.originalEvent.body.warnings.invalidLevels.length) {
        this.messageService.add({
          detail: `L’importation du fichier « ${
            event.files[0].name
          } » est terminée. Toutefois, ces détaillants n’ont pas été enregistrés dans la base car leurs niveaux ne sont pas valides : ** ${event.originalEvent.body.warnings.invalidLevels
            .map(
              (invalidLevel) =>
                `"${invalidLevel.level}" à la ligne ${invalidLevel.line}`,
            )
            .join(' * , * ')} **`,
          severity: 'warn',
          summary: 'Importation complétée',
        });
      }

      if (event.originalEvent.body.warnings.invalidLongitudes.length) {
        this.messageService.add({
          detail: `L’importation du fichier « ${
            event.files[0].name
          } » est terminée. Toutefois, ces détaillants n’ont pas été enregistrés dans la base car leurs longitudes ne sont pas valides : ** ${event.originalEvent.body.warnings.invalidLongitudes
            .map(
              (invalidLongitude) =>
                `"${invalidLongitude.longitude}" à la ligne ${invalidLongitude.line}`,
            )
            .join(' * , * ')} **`,
          severity: 'warn',
          summary: 'Importation complétée',
        });
      }

      if (event.originalEvent.body.warnings.invalidNames.length) {
        this.messageService.add({
          detail: `L’importation du fichier « ${
            event.files[0].name
          } » est terminée. Toutefois, ces détaillants n’ont pas été enregistrés dans la base car leurs noms ne sont pas valides : ** ${event.originalEvent.body.warnings.invalidNames
            .map(
              (invalidName) =>
                `"${invalidName.name}" à la ligne ${invalidName.line}`,
            )
            .join(' * , * ')} **`,
          severity: 'warn',
          summary: 'Importation complétée',
        });
      }

      if (event.originalEvent.body.warnings.invalidPhones.length) {
        this.messageService.add({
          detail: `L’importation du fichier « ${
            event.files[0].name
          } » est terminée. Toutefois, ces numéros de mobile détaillants n’ont pas été enregistrés dans la base car ils ne sont pas valides : ** ${event.originalEvent.body.warnings.invalidPhones
            .map(
              (invalidPhone) =>
                `"${invalidPhone.phone}" à la ligne ${invalidPhone.line}`,
            )
            .join(' * , * ')} **`,
          severity: 'warn',
          summary: 'Importation complétée',
        });
      }
    } else {
      this.messageService.add({
        severity: 'success',
        summary: 'Importation réussie',
        detail: `L’importation et le traitement du fichier « ${event.files[0].name} » sont terminés.`,
      });

      if (
        event.originalEvent.body?.warnings &&
        event.originalEvent.body.warnings.modifiedPhones.length
      ) {
        this.messageService.add({
          detail: `Remarque : le fichier « ${
            event.files[0].name
          } » a modifié certains numéros de mobile détaillants dans la base. Ainsi, leurs statuts et logins ont été réinitialisés, car les détaillants indiqués avaient déjà un numéro enregistré : ** ${event.originalEvent.body.warnings.modifiedPhones
            .map(
              (ignoredPhone) =>
                `Ligne ${ignoredPhone.line} : nouveau numéro et login "${ignoredPhone.newPhone}" (numéro précédemment enregistré ${ignoredPhone.existingPhone})`,
            )
            .join(' * , * ')} **`,
          severity: 'info',
          summary: 'Logins réinitialisés',
        });
      }
    }

    this.handlerIndexDataTable.reset();

    this.isLoading = false;
    this.isHandlersDialogVisible = false;
    this.isItinerariesDialogVisible = false;
  }

  onUploadItinerariesSuccess(event: {
    files: File[];
    originalEvent: HttpResponse<{
      url: string;
      warnings?: {
        ignoredSheets: string[];
        ignoredVisitors: string[];
      };
    }>;
  }) {
    if (
      event.originalEvent.body?.warnings &&
      (event.originalEvent.body.warnings.ignoredSheets.length ||
        event.originalEvent.body.warnings.ignoredVisitors.length)
    ) {
      if (event.originalEvent.body.warnings.ignoredSheets.length) {
        this.messageService.add({
          detail: `L’importation du fichier « ${
            event.files[0].name
          } » est terminée. Toutefois, une seule feuille peut être importée et les feuilles suivantes ont été ignorées : ** ${event.originalEvent.body.warnings.ignoredSheets.join(
            ' * , * ',
          )} **`,
          severity: 'warn',
          summary: 'Importation complétée',
        });
      }

      if (event.originalEvent.body.warnings.ignoredVisitors.length) {
        this.messageService.add({
          detail: `L’importation du fichier « ${
            event.files[0].name
          } » est terminée. Toutefois, ces codes auditeurs ne sont pas présents dans la base : ** ${event.originalEvent.body.warnings.ignoredVisitors.join(
            ' * , * ',
          )} **`,
          severity: 'warn',
          summary: 'Importation complétée',
        });
      }
    } else {
      this.messageService.add({
        severity: 'success',
        summary: 'Importation réussie',
        detail: `L’importation et le traitement du fichier « ${event.files[0].name} » sont terminés.`,
      });
    }

    this.handlerIndexDataTable.reset();

    this.isLoading = false;
    this.isHandlersDialogVisible = false;
    this.isItinerariesDialogVisible = false;
  }

  uploadHandlers() {
    this.isHandlersDialogVisible = true;
  }

  uploadItineraries() {
    this.isItinerariesDialogVisible = true;
  }
}
