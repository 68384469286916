<ng-template #loadingSkeleton>
  <div class="field col-12 md:col-6 md:pr-6 pr-0">
    <h5>Loading...</h5>

    <div class="custom-skeleton p-4">
      <div class="flex mb-3">
        <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>

        <div>
          <p-skeleton width="10rem" styleClass="mb-2"></p-skeleton>
          <p-skeleton width="5rem" styleClass="mb-2"></p-skeleton>
          <p-skeleton height=".5rem"></p-skeleton>
        </div>
      </div>

      <p-skeleton width="100%" height="150px"></p-skeleton>

      <div class="flex justify-content-between mt-6">
        <p-skeleton width="4rem" height="2rem"></p-skeleton>
        <p-skeleton width="4rem" height="2rem"></p-skeleton>
      </div>
    </div>
  </div>
</ng-template>

<div class="grid flex frex-wrap">
  <div class="col-12">
    <div class="card mb-0">
      <div
        class="flex align-items-start flex-column lg:justify-content-between lg:flex-row"
        *ngIf="organizationType; else loadingSkeleton"
      >
        <div class="flex flex-column">
          <div class="flex flex-wrap align-items-center">
            <div class="font-bold text-3xl text-900">
              {{ organizationType.nameFr }}
            </div>
          </div>

          <div class="flex align-items-center text-700 flex-wrap">
            <div class="mr-5 flex align-items-center mt-3">
              <i class="pi pi-clock mr-2"></i>
              <span
                >Créé :
                {{
                  organizationType.createdAt | date : 'long' : '' : 'fr'
                }}</span
              >
            </div>

            <div class="mr-5 flex align-items-center mt-3">
              <i class="pi pi-history mr-2"></i>
              <span
                >Mis à jour :
                <ng-container *ngIf="organizationType.updatedAt; else notSet">
                  {{ organizationType.updatedAt | date : 'long' : '' : 'fr' }}
                </ng-container>
              </span>
            </div>
          </div>
        </div>

        <div class="mt-3 lg:mt-0 flex align-items-center gap-4">
          <p-splitButton
            [disabled]="isLoading"
            [icon]="actionMenuItems[0].icon!!"
            [label]="actionMenuItems[0].label!!"
            [model]="actionMenuItems"
            [styleClass]="actionDefaultStyleClass"
            (onClick)="actionMenuItems[0].command!!()"
          ></p-splitButton>
        </div>
      </div>
    </div>
  </div>

  <p-messages></p-messages>

  <div class="col-12 md:col-5">
    <div class="card">
      <h3 class="text-2xl font-bold mb-3">Général</h3>

      <div>
        <ul
          class="list-none p-0 m-0 flex-grow-1"
          *ngIf="organizationType; else loadingSkeleton"
        >
          <li
            class="flex align-items-center py-3 px-2 surface-border flex-wrap"
          >
            <div
              class="text-500 w-full xl:w-4 font-bold overflow-hidden text-overflow-ellipsis"
            >
              Nom
            </div>

            <div class="text-900 w-full xl:w-8">
              <div class="flex align-items-center mb-4">
                <img class="mr-2" src="/assets/flags/fr.svg" height="20" />

                <ng-container *ngIf="organizationType.nameFr; else notSet">{{
                  organizationType.nameFr
                }}</ng-container>
              </div>

              <div class="flex align-items-center">
                <img class="mr-2" src="/assets/flags/ma.svg" height="20" />

                <ng-container *ngIf="organizationType.nameAr; else notSet">{{
                  organizationType.nameAr
                }}</ng-container>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="col-12 md:col-7">
    <div class="card mb-0" *ngIf="organizationType; else loadingSkeleton">
      <h3 class="text-2xl font-bold mb-3">
        {{ organizationType.programs?.length }} programmes
      </h3>

      <div>
        <p-table
          styleClass="p-datatable-gridlines"
          [currentPageReportTemplate]="
            'Enregistrements {first} à {last} sur un total de {totalRecords}'
          "
          [dataKey]="'code'"
          [loading]="isLoading"
          [paginator]="true"
          [responsiveLayout]="'scroll'"
          [rowHover]="true"
          [rows]="10"
          [rowsPerPageOptions]="[10, 25, 50]"
          [scrollable]="true"
          [showCurrentPageReport]="true"
          [value]="organizationType.programs || []"
        >
          <ng-template pTemplate="header">
            <tr>
              <th></th>

              <th pSortableColumn="nameFr" pFrozenColumn>
                <div class="flex justify-content-between align-items-center">
                  <div class="flex align-items-center gap-2">Programme</div>

                  <p-sortIcon field="nameFr"></p-sortIcon>
                </div>
              </th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-program>
            <ng-container *ngLet="asProgramDto(program) as program">
              <tr *ngIf="program">
                <td style="text-align: center">
                  <div class="flex" style="line-height: 0">
                    <p-image
                      class="shadow-4"
                      [preview]="true"
                      [src]="imageBaseUrl + program.imageUrl"
                      width="100"
                    ></p-image>
                  </div>
                </td>

                <td pFrozenColumn>
                  <a [routerLink]="'/programs/' + program.code">
                    {{ program.nameFr }}

                    <i class="pi pi-external-link"></i>
                  </a>
                </td>
              </tr>
            </ng-container>
          </ng-template>

          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="2">Aucun programme trouvé.</td>
            </tr>
          </ng-template>

          <ng-template pTemplate="loadingbody">
            <tr>
              <td colspan="2">Chargement en cours. Veuillez patienter...</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>

<ng-template #notSet>
  <span class="text-pink-600 font-italic">Non défini</span>
</ng-template>

<p-dialog
  header="Confirmation"
  [breakpoints]="{ '960px': '50vw', '640px': '85vw' }"
  [modal]="true"
  [resizable]="false"
  [style]="{ width: '450px' }"
  [(visible)]="isConfirmDialogVisible"
>
  <div class="flex align-items-center justify-content-center">
    <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>

    <div>
      <span [innerHTML]="confirmationDialogMessage"></span>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <button
      class="p-button-text"
      pButton
      pRipple
      icon="pi pi-times"
      label="Non"
      (click)="isConfirmDialogVisible = false"
    ></button>

    <button
      class="p-button-text p-button-danger"
      pButton
      pRipple
      icon="pi pi-check"
      label="Oui"
      [loading]="isLoading"
      (click)="confirm()"
    ></button>
  </ng-template>
</p-dialog>
