import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { UserApi } from '@be-green/api-services';
import {
  EditUserDto,
  HandlerDto,
  UserDto,
  UserRole,
  UserStatusCode,
  ValueLabelDto,
} from '@be-green/dto';
import {
  AuthenticationService,
  ErrorDialogComponent,
  ErrorService,
  InputValidationService,
  SeoService,
  UtilsService,
} from '@be-green/ui-services';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { first, share } from 'rxjs';
import { LayoutService } from '../../layout/layout.service';

@Component({
  selector: 'be-green--admin--accounts-index',
  templateUrl: './accounts-index.component.html',
  styleUrls: ['./accounts-index.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AccountsIndexComponent implements OnInit {
  accounts!: UserDto[];
  isLoading = false;
  isUserActionDialogVisible = false;
  isUserDialogVisible = false;
  recordCount = 0;
  roles: ValueLabelDto[] = [
    { value: UserRole.User, label: 'User' },
    { value: UserRole.Handler, label: 'Handler' },
    { value: UserRole.Supervisor, label: 'Supervisor' },
    { value: UserRole.Admin, label: 'Admin' },
  ];
  statuses: ValueLabelDto[] = [
    { value: UserStatusCode.Active, label: 'Actif' },
    { value: UserStatusCode.New, label: 'Nouveau' },
    { value: UserStatusCode.Deactivated, label: 'Désactivé' },
    { value: UserStatusCode.Closed, label: 'Clôturé' },
  ];
  user: UserDto | null = null;
  userAction?: 'deactivate' | 'delete' | 'reactivate';
  userActions = {
    deactivate: { verb: 'désactiver', adjective: 'désactivé' },
    delete: { verb: 'clôturer', adjective: 'clôturé' },
    reactivate: { verb: 'réactiver', adjective: 'réactivé' },
  };
  userDialogHeader = '';
  userForm!: FormGroup;
  userRoles: ValueLabelDto[] = [
    { value: UserRole.Supervisor, label: 'Superviseur' },
    { value: UserRole.Admin, label: 'Admin' },
  ];

  constructor(
    @Inject('API_PAGE_SIZE') readonly apiPageSize: number,
    readonly authenticationService: AuthenticationService,
    private readonly dialogService: DialogService,
    private readonly errorService: ErrorService,
    private readonly formBuilder: FormBuilder,
    private readonly layoutService: LayoutService,
    private readonly messageService: MessageService,
    private readonly seoService: SeoService,
    private readonly userApi: UserApi,
  ) {
    this.layoutService.registerBreadcrumbs([{ label: 'Comptes' }]);
    this.seoService.setTitle('Comptes - Admin - Be Green');
  }

  ngOnInit(): void {
    this.isLoading = true;
  }

  get userFormControls() {
    return this.userForm?.controls as {
      firstName: AbstractControl;
      lastName: AbstractControl;
      email: AbstractControl;
      role: AbstractControl;
    };
  }

  addNewUser() {
    this.isLoading = false;
    this.user = null;

    this.initUserForm();

    this.userDialogHeader = 'Nouvel utilisateur';
    this.isUserDialogVisible = true;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  asUserDto(account: any): UserDto {
    return account as UserDto;
  }

  confirmUserAction() {
    if (!this.user || !this.userAction) {
      return;
    }

    this.isLoading = true;

    this.userApi[this.userAction](this.user.code)
      .pipe(first())
      .subscribe({
        complete: async () => {
          this.isUserActionDialogVisible = false;

          this.fetchUser((this.user as UserDto).code).subscribe({
            next: (data) => {
              const index = this.accounts.findIndex(
                (user) => user.code === (this.user as UserDto).code,
              );

              this.accounts[index] = data;

              this.messageService.add({
                detail: `Utilisateur ${(this.user as UserDto).email} ${
                  this.userActions[
                    this.userAction as 'deactivate' | 'reactivate'
                  ].adjective
                }`,
                key: 'users',
                life: 3000,
                severity: 'success',
                summary: 'Succès',
              });

              this.isUserDialogVisible = false;
              this.user = null;

              this.isLoading = false;
            },

            error: async (error: HttpErrorResponse) => {
              const { title, message } = await this.errorService.handleError(
                error,
              );

              this.isLoading = false;

              this.messageService.add({
                detail: message,
                key: 'users',
                severity: 'error',
                summary: title,
              });
            },
          });
        },

        error: async (error: HttpErrorResponse) => {
          const { title, message } = await this.errorService.handleError(error);

          this.messageService.add({
            detail: message,
            key: 'users',
            severity: 'error',
            summary: title,
          });

          this.user = null;
          this.isUserActionDialogVisible = false;
          this.isLoading = false;
        },
      });
  }

  deactivateUser(user: UserDto) {
    this.isLoading = false;
    this.userAction = 'deactivate';
    this.isUserActionDialogVisible = true;
    this.user = { ...user };
  }

  deleteUser(user: UserDto) {
    this.isLoading = false;
    this.userAction = 'delete';
    this.isUserActionDialogVisible = true;
    this.user = { ...user };
  }

  editUser(user: UserDto) {
    if (
      !user.roles ||
      !user.roles.length ||
      ![UserRole.Admin, UserRole.Supervisor].includes(user.roles[0])
    ) {
      return;
    }

    this.isLoading = false;
    this.user = { ...user };

    this.initUserForm();

    this.userFormControls.email.patchValue(user.email);
    this.userFormControls.email.disable();

    this.userFormControls.firstName.patchValue(user.person?.firstName);
    this.userFormControls.lastName.patchValue(user.person?.lastName);

    this.userFormControls.role.patchValue(user.roles[0]);
    if (user.code === this.authenticationService.decodedToken?.sub) {
      this.userFormControls.role.disable();
    }

    this.userDialogHeader = `Editer le compte « ${user.email} »`;
    this.isUserDialogVisible = true;
  }

  eventTargetValue(event: Event) {
    return (event.target as HTMLInputElement).value;
  }

  fetchNextPage(lazyLoadEvent: LazyLoadEvent) {
    this.isLoading = true;

    this.userApi
      .getAll(
        UtilsService.fromLazyLoadEventToQueryUiDto(
          lazyLoadEvent,
          this.apiPageSize,
        ),
      )
      .subscribe({
        next: (data) => {
          this.accounts = data.rows;
          this.recordCount = data.count;

          this.isLoading = false;
        },

        error: (error: HttpErrorResponse) => {
          this.errorService.handleError(error);

          this.isLoading = false;
        },
      });
  }

  private fetchUser(code: string) {
    return this.userApi.getOne(code).pipe(first(), share());
  }

  getRoleStyleClass(
    role: UserRole,
  ):
    | 'tlb--status-active'
    | 'tlb--status-future'
    | 'tlb--status-submitted'
    | 'tlb--status-terminated'
    | '' {
    switch (role) {
      case UserRole.Admin:
        return 'tlb--status-terminated';

      case UserRole.Handler:
        return 'tlb--status-submitted';

      case UserRole.Supervisor:
        return 'tlb--status-future';

      case UserRole.System:
        return '';

      case UserRole.User:
        return 'tlb--status-active';
    }
  }

  getStatusTagSeverity(
    statusCode: string,
  ): 'primary' | 'success' | 'info' | 'warning' | 'danger' {
    return UtilsService.getUserStatusTagSeverity(statusCode);
  }

  goToPerson(user: UserDto) {
    if (!user || !user.person || !user.roles) {
      return;
    }

    switch (user.roles[0]) {
      case UserRole.User:
        return `/users/${user.code}`;

      case UserRole.Handler:
        return user.person.handler
          ? `/handlers/${(user.person.handler as HandlerDto).code}`
          : '';

      default:
        return '';
    }
  }

  private initUserForm() {
    this.userForm = this.formBuilder.group({
      email: [
        null,
        Validators.compose([
          Validators.required,
          InputValidationService.email,
          Validators.maxLength(64),
        ]),
      ],
      firstName: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(64)]),
      ],
      lastName: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(64)]),
      ],
      role: [
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern(
            new RegExp(`^(${UserRole.Supervisor}|${UserRole.Admin})$`),
          ),
        ]),
      ],
    });
  }

  reactivateUser(user: UserDto) {
    this.isLoading = false;
    this.userAction = 'reactivate';
    this.isUserActionDialogVisible = true;
    this.user = { ...user };
  }

  saveUser() {
    this.userForm.markAllAsTouched();

    if (this.userForm.invalid) {
      return;
    }

    this.isLoading = true;

    let editUserDto: EditUserDto = this.userForm.value;

    if (this.user) {
      editUserDto = { ...editUserDto, email: this.user.email };

      if (this.authenticationService.decodedToken?.sub === this.user.code) {
        editUserDto = {
          ...editUserDto,
          role: (this.user.roles as UserRole[])[0],
        };
      }

      this.userApi
        .update(this.user.code, editUserDto)
        .pipe(first())
        .subscribe({
          complete: async () => {
            this.fetchUser((this.user as UserDto).code).subscribe({
              next: (data) => {
                const index = this.accounts.findIndex(
                  (user) => user.code === (this.user as UserDto).code,
                );

                this.accounts[index] = data;

                this.messageService.add({
                  detail: `Utilisateur ${
                    (this.user as UserDto).email
                  } mis à jour`,
                  key: 'users',
                  life: 3000,
                  severity: 'success',
                  summary: 'Succès',
                });

                this.isUserDialogVisible = false;
                this.user = null;

                this.isLoading = false;
              },

              error: async (error: HttpErrorResponse) => {
                const { title, message } = await this.errorService.handleError(
                  error,
                );

                this.isLoading = false;

                this.messageService.add({
                  detail: message,
                  key: 'users',
                  severity: 'error',
                  summary: title,
                });
              },
            });
          },

          error: async (error: HttpErrorResponse) => {
            const { title, message } = await this.errorService.handleError(
              error,
            );

            this.isLoading = false;

            this.messageService.add({
              detail: message,
              key: 'users',
              severity: 'error',
              summary: title,
            });
          },
        });
    } else {
      this.userApi
        .create(editUserDto)
        .pipe(first())
        .subscribe({
          next: async (value: { code: string }) => {
            this.fetchUser(value.code).subscribe({
              next: (data) => {
                this.accounts = [...this.accounts, data];

                this.messageService.add({
                  detail: `Utilisateur ${editUserDto.email} créé`,
                  key: 'users',
                  life: 3000,
                  severity: 'success',
                  summary: 'Succès',
                });

                this.isUserDialogVisible = false;
                this.user = null;

                this.isLoading = false;
              },

              error: async (error: HttpErrorResponse) => {
                const { title, message } = await this.errorService.handleError(
                  error,
                );

                this.isLoading = false;

                this.messageService.add({
                  detail: message,
                  key: 'users',
                  severity: 'error',
                  summary: title,
                });
              },
            });
          },

          error: async (error: HttpErrorResponse) => {
            if (
              error.status === 409 &&
              error.error.message ===
                'EMAIL_AND_ROLE_COMBINATION_MUST_BE_UNIQUE'
            ) {
              this.dialogService.open(ErrorDialogComponent, {
                data: {
                  message: 'Veuillez sélectionner une autre adresse e-mail',
                },
                header: 'E-mail déjà enregistré',
                width: '450px',
                contentStyle: {
                  'border-bottom-right-radius': '0.375rem',
                  'border-bottom-left-radius': '0.375rem',
                },
              });
            } else {
              const { title, message } = await this.errorService.handleError(
                error,
              );

              this.messageService.add({
                detail: message,
                key: 'users',
                severity: 'error',
                summary: title,
              });
            }

            this.isLoading = false;
          },
        });
    }
  }
}
