import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiInterceptor implements HttpInterceptor {
  constructor(
    @Inject('API_BASE_URL') private apiBaseUrl: string,
    @Inject('APP_VERSION') private appVersion: string,
  ) {}

  // Function to generate a unique random request reference
  private generateRequestReference(): string {
    return `${Date.now()}-${Math.random().toString(36).substring(2, 15)}`;
  }

  intercept(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    request: HttpRequest<any>,
    next: HttpHandler,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<HttpEvent<any>> {
    if (request.url.startsWith('/')) {
      request = request.clone({
        setHeaders: {
          'x-app-version': this.appVersion,
          'x-request-reference': this.generateRequestReference(),
        },

        url: `${this.apiBaseUrl}${request.url}`,
      });
    }

    return next.handle(request);
  }
}

export const apiInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ApiInterceptor,
  multi: true,
};
